<template>
  <div class="post-container">
    <!-- 头部 -->
    <div class="post-header">
      <div class="tab-bar">
        <div :class="['tab-item', selectedTabValue === item.value ? 'actived' : '']" v-for="(item, index) in tabList" :key="index" @click="handleSwitchTab(item.value)">{{ item.label }}</div>
      </div>
      <div class="search-box">
        <div class="publish-btn btn-hover" @click="handlePublish" v-if="$route.path == '/position'">+ 发布职位</div>
        <div class="title" v-else>
          <p class="bold" @click="$router.go(-1)">{{ $route.query.positionName }}</p>
          <img class="icon" src="@/assets/images/company/arrow-icon.png" alt="" />
          <p>查看所有人才</p>
        </div>
        <div class="input-box">
          <el-input placeholder="搜索" v-model.trim="keywords" @keyup.enter.native="handleEnter">
            <el-button slot="append" icon="el-icon-search" @click="handleEnter"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="post-content">
      <router-view />
    </div>

    <!-- 公司审核状态弹窗 -->
    <CompanyAuditStatus v-if="showAuditStatusDialog" @cancel="showAuditStatusDialog = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompanyAuditStatus from '@/components/CompanyAuditStatus'
import { postStatusList } from '@/utils/statusType'
import { getPostList } from '@/api/position'
export default {
  name: 'PostView',
  components: { CompanyAuditStatus },
  data() {
    return {
      showAuditStatusDialog: false, // 是否显示审核状态弹窗
      tabList: postStatusList, // tab列表
      selectedTabValue: '', // 选中的tab值
      keywords: '', // 搜索关键字
    }
  },
  computed: {
    ...mapGetters(['getCompanyInfo']),
  },
  created() {
    if (this.$route.query.status) {
      this.selectedTabValue = Number(this.$route.query.status)
    } else {
      this.selectedTabValue = this.$route.query.status || ''
    }
    this.keywords = this.$route.query.keywords || ''
  },
  methods: {
    /**
     * 搜索
     */
    handleSearchPost() {
      let status = this.selectedTabValue
      let keywords = this.keywords
      this.$router.replace(`/position?status=${status}&keywords=${keywords}`)
    },

    /**
     * 回车事件
     */
    handleEnter() {
      if (this.keywords != this.$route.query.keywords) {
        this.handleSearchPost()
      }
    },

    /**
     * 切换tab
     */
    handleSwitchTab(value) {
      if (this.selectedTabValue !== value) {
        this.selectedTabValue = value
        this.handleSearchPost()
      }
    },

    /**
     * 发布职位
     */
    async handlePublish() {
      if (this.getCompanyInfo.auditStatus == 2 || this.getCompanyInfo.auditStatus == 99) {
        this.showAuditStatusDialog = true
      } else {
        let { data } = await getPostList({
          pageNum: 1,
          pageSize: 1,
        })
        if (data.list.length > 0) {
          this.$verifyPermission(() => {
            this.$router.push('/position/publish')
          }, 1)
        } else {
          this.$router.push('/position/publish')
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.post-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .post-header {
    display: flex;
    flex-direction: column;
    // position: fixed;
    z-index: 1;
    // background: rgba(243, 246, 250);
    // background: #dfe2e9;
    // padding: 0 4px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 1410px;

    .tab-bar {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tab-item {
        margin-right: 7px;
        font-size: 15px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #323741;
        padding: 3px 9px;
        cursor: pointer;
        border: 1px solid transparent;
        transition: all 0.3s;

        &:hover {
          color: #3971ff;
        }
      }

      .actived {
        border: 1px solid #3971ff;
        background: #e3ebff;
        border-radius: 3px;
        color: #3971ff;
      }
    }

    .search-box {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .publish-btn {
        padding: 5px 10px;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        cursor: pointer;
        color: #ffffff;
        border-radius: 296px;
        background: linear-gradient(134deg, #66d4ff 0%, #3971ff 100%);
        box-shadow: 0px 11px 18px -10px rgba(0, 49, 123, 0.4);
      }

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #4e555d;

        .bold {
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: #171d26;
          cursor: pointer;
        }

        .icon {
          padding: 7px;
          width: 12px;
          height: 10px;
        }
      }

      .input-box {
        height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        /deep/ .el-input__inner {
          height: 35px;
          border: 1px solid #e2e5eb;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #999999;
          width: 294px;
          background: #ffffff;
          border-radius: 119px 119px 119px 119px;
          padding-right: 35px;

          &:focus {
            border: 1px solid #3971ff;
          }
        }

        /deep/ .el-input__icon {
          line-height: 32px;
          color: #666666;
          font-size: 16px;
          padding-right: 5px;
        }

        /deep/ .el-input-group__append {
          position: absolute;
          right: 0;
          top: 0;
          width: 40px;
          height: 100%;
          background: transparent;
          padding: 0;
          cursor: pointer;
          line-height: 32px;
          text-align: center;
          border: none;
        }
      }
    }
  }

  .post-content {
    // position: absolute;
    width: 100%;
    // min-height: calc(100vh - 175px);
    // top: 118px;
    // padding: 0 4px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cfcfcf;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      background: #e0e0e0;
    }
  }
}
</style>
