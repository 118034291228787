import Vue from "vue";
import { getSalaryNum } from "./statusType";
/**
 * 时间格式化
 */
Vue.filter("dateFormat", (dateStr, format = "YYYY-MM-DD HH:mm:ss", fill = true) => {
  if (!dateStr) return " ";
  const date = new Date(dateStr);
  let dateNumFun;
  if (fill) {
    dateNumFun = (num) => String(num).padStart(2, "0");
  } else {
    dateNumFun = (num) => String(num).padStart(1, "0");
  }
  const config = {
    YYYY: date.getFullYear(),
    MM: dateNumFun(date.getMonth() + 1),
    DD: dateNumFun(date.getDate()),
    HH: dateNumFun(date.getHours()),
    mm: dateNumFun(date.getMinutes()),
    ss: dateNumFun(date.getSeconds()),
  };
  for (const key in config) {
    format = format.replace(key, config[key]);
  }
  return format;
});

/**
 * 根据日期获取年龄
 */
Vue.filter("getAge", (dateStr) => {
  let birthdays = new Date(dateStr);
  let currentDate = new Date();
  let age =
    currentDate.getFullYear() -
    birthdays.getFullYear() -
    (currentDate.getMonth() < birthdays.getMonth() ||
    (currentDate.getMonth() == birthdays.getMonth() && currentDate.getDate() < birthdays.getDate())
      ? 1
      : 0);
  return age;
});

/**
 * 根据日期获取星期
 */
Vue.filter("getWeek", (dateStr) => {
  var week = new Date(dateStr).getDay();
  let weekName;
  if (week == 0) {
    weekName = "周日";
  } else if (week == 1) {
    weekName = "周一";
  } else if (week == 2) {
    weekName = "周二";
  } else if (week == 3) {
    weekName = "周三";
  } else if (week == 4) {
    weekName = "周四";
  } else if (week == 5) {
    weekName = "周五";
  } else if (week == 6) {
    weekName = "周六";
  }
  return weekName;
});

/**
 * 数字格式化千分位
 */
Vue.filter("numberFormat", (num) => {
  let str = num.toString();
  let reg = /\d{1,3}(?=(\d{3})+\b)/g;
  str = str.replace(reg, "$&,");
  return str;
});

/**
 * 友好日期格式化
 */
Vue.filter("friendDate", (timeStamp) => {
  if (!timeStamp) return "";
  var timeStampStamp = timeStamp;
  var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;
  var day = hour * 24;
  var week = day * 7;
  var month = day * 30;
  var now = new Date().getTime(); //获取当前时间毫秒
  var diffValue = now - timeStampStamp; //时间差

  if (diffValue < 0) {
    console.log("diffValue<0", timeStamp, timeStampStamp, now, diffValue);
    return "刚刚";
  }
  var minC = diffValue / minute; //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour;
  var dayC = diffValue / day;
  var weekC = diffValue / week;
  var monthC = diffValue / month;
  var result = "2";
  if (monthC >= 1 && monthC <= 3.9) {
    result = " " + parseInt(monthC) + "月前";
  } else if (weekC >= 1 && weekC <= 3.9) {
    result = " " + parseInt(weekC) + "周前";
  } else if (dayC >= 1 && dayC <= 6.9) {
    result = " " + parseInt(dayC) + "天前";
  } else if (hourC >= 1 && hourC <= 23.9) {
    result = " " + parseInt(hourC) + "小时前";
  } else if (minC >= 1 && minC <= 59.9) {
    result = " " + parseInt(minC) + "分钟前";
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚";
  } else {
    var timeStamp = new Date();
    timeStamp.setTime(timeStampStamp);
    var Nyear = timeStamp.getFullYear();
    {
    }
    var Nmonth = timeStamp.getMonth() + 1 < 10 ? "0" + (timeStamp.getMonth() + 1) : timeStamp.getMonth() + 1;
    var Ndate = timeStamp.getDate() < 10 ? "0" + timeStamp.getDate() : timeStamp.getDate();
    var Nhour = timeStamp.getHours() < 10 ? "0" + timeStamp.getHours() : timeStamp.getHours();
    var Nminute = timeStamp.getMinutes() < 10 ? "0" + timeStamp.getMinutes() : timeStamp.getMinutes();
    var Nsecond = timeStamp.getSeconds() < 10 ? "0" + timeStamp.getSeconds() : timeStamp.getSeconds();
    result = Nyear + "-" + Nmonth + "-" + Ndate;
  }
  return result;
});

Vue.filter("getSalaryNum", getSalaryNum);
