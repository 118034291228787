import request from '@/utils/request';

/**
 * 发送验证码
 */
export const sendCode = params => {
  return request({
    url: '/web/user/getCode',
    method: 'get',
    params
  });
};

/**
 * 用户登录
 */
export const login = data => {
  return request({
    url: '/auth/oauth/token',
    method: 'post',
    data,
    auth:false
  });
};

/**
 * 用户退出登录
 */
export const logout = data => {
  return request({
    url: '/auth/oauth/logout',
    method: 'get',
    data
  });
};

/**
 * 用户注销
 */
export const logoff = data => {
  return request({
    url: '/web/logOff',
    method: 'post',
    data
  });
};

/**
 * 获取其他用户详细信息
 */
export const getOtherUserInfo = data => {
  return request({
    url: '/web/enterprise/user/getOtherUserInfo',
    method: 'post',
    data
  });
};

/**
 * 统计职位沟通次数
 */
export const countCommuninateConnectTime = data => {
  return request({
    url: '/web/enterprise/user/chat',
    method: 'post',
    data
  });
};

/**
 * 获取常用语列表
 */
export const getCommonLanguageList = data => {
  return request({
    url: '/web/enterprise/user/userCommonPhrase/list',
    method: 'post',
    data
  });
};

/**
 * 获取交换电话信息
 */
export const getExchangePhoneInfo = data => {
  return request({
    url: '/web/enterprise/user/hr/exchangeMobile/info',
    method: 'post',
    data
  });
};

/**
 * 交换电话
 */
export const exchangePhone = data => {
  return request({
    url: '/web/enterprise/user/hr/exchangeMobile/operation',
    method: 'post',
    data
  });
};

/**
 * 实名认证
 */
export const userAuthentication = data => {
  return request({
    url: '/web/enterprise/user/authentication',
    method: 'post',
    data
  });
};

/**
 * 获取用户权限列表
 */
export const getUserPowerList = data => {
  return request({
    url: '/web/enterprise/user/service/powerList',
    method: 'post',
    data
  });
};

/**
 * 删除聊天用户
 */
export const deleteContactUser = data => {
  return request({
    url: 'web/enterprise/user/filterUser/delete',
    method: 'post',
    data
  });
};

// 获取腾讯签名
export const getUserSig = (data={})=>{
  return request({
    url: 'web/im/enterprise/genUserSig',
    method: 'post',
    data
  });
}