<template>
  <div
    :class="[
      'message-abstract-text',
      `message-abstract-text-${highlightType}`,
      `message-abstract-text-${displayType}`,
    ]"
  >
    <span
      v-for="(contentItem, index) in contentText"
      :key="index"
      :class="[(contentItem && contentItem.isHighlight) ? 'highlight' : 'normal']"
    >
      {{ decodeTextMessage(contentItem.text) }}
    </span>
  </div>
</template>
<script lang="ts">
import { ref, withDefaults } from '../../../../../adapter-vue';
import { decodeTextMessage } from '../../../../TUIChat/utils/emojiList';
import { IHighlightContent } from '../../../type';
interface IProps {
  content: Array<IHighlightContent>;
  highlightType: 'font' | 'background';
  displayType: 'info' | 'bubble';
}
const __sfc_main = {};
__sfc_main.props = {
  content: {
    key: "content",
    required: false,
    type: Array,
    default: () => ([] as Array<IHighlightContent>)
  },
  highlightType: {
    key: "highlightType",
    required: false,
    type: String,
    default: 'font'
  },
  displayType: {
    key: "displayType",
    required: false,
    type: String,
    default: 'info'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const contentText = ref<Array<{
    text: string;
    isHighlight: boolean;
  }>>(props.content);
  return {
    decodeTextMessage,
    contentText
  };
};
export default __sfc_main;
</script>
<style scoped lang="scss">
@import "../../../../../assets/styles/common";

.message-abstract-text {
  justify-content: flex-start;

  &-font {
    color: #999;

    .highlight {
      color: #007aff;
    }

    .normal {
      color: #999;
    }
  }

  &-background {
    color: #1f2329;

    .highlight {
      background-color: #007aff33;
    }

    .normal {
      font-size: 14px;
    }
  }

  &-info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;

    .highlight {
      font-size: 12px;
    }

    .normal {
      font-size: 12px;
    }
  }

  &-bubble {
    font-size: 14px;

    .highlight {
      font-size: 14px;
    }

    .normal {
      font-size: 14px;
    }
  }
}
</style>
