<template>
  <!-- 审核状态 0待审核 1审核通过 2审核拒绝 99资料待完善 -->
  <div class="audit-box-container">
    <el-dialog :visible="true" width="526px" top="30vh" :before-close="handleCancel">
      <div class="title">
        <span>您当前的企业状态为：</span>
        <span class="red" v-if="getCompanyInfo.auditStatus == 2">审核失败</span>
        <span class="red" v-else-if="getCompanyInfo.auditStatus == 99">未完善</span>
      </div>
      <div class="tips" v-if="getCompanyInfo.auditStatus == 2">{{ getCompanyInfo.auditReason }}</div>
      <div class="tips" v-else-if="getCompanyInfo.auditStatus == 99">{{ getCompanyInfo.companyNoCompleteInfo }}</div>
      <span slot="footer" class="dialog-footer">
        <div class="btn" @click="handleCancel" v-if="getCompanyInfo.auditStatus == 2">好的</div>
        <div class="btn" @click="handleConfirm" v-else-if="getCompanyInfo.auditStatus == 99">去完善</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AuditStatus',
  computed: {
    ...mapGetters(['getCompanyInfo']),
  },
  methods: {
    /**
     * 点击确定
     */
    handleCancel() {
      this.$emit('cancel')
    },

    /**
     * 点击确定
     */
    handleConfirm() {
      this.$emit('cancel')
      this.$router.push('/company/info')
    },
  },
}
</script>

<style lang="less" scoped>
.audit-box-container {
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-dialog__headerbtn {
    top: 9px;
    right: 9px;
    font-size: 24px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: #7e8189 !important;
  }

  .title {
    margin-top: -20px;
    font-size: 20px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: 400;
    color: #555555;
    text-align: center;

    .red {
      color: #ff4949;
    }

    .blue {
      color: #3971ff;
    }
  }

  .tips {
    padding: 0 20px;
    margin-top: 32px;
    font-size: 15px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }

  .dialog-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .btn {
      cursor: pointer;
      width: 208px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(134deg, #66aeff 0%, #3971ff 100%);
      box-shadow: 9px 11px 22px -10px rgba(0, 49, 123, 0.2);
      border-radius: 300px 300px 300px 300px;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
