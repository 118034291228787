import store from '@/store'
import Vue from "vue";

/**
 * 输入框聚焦
 */
Vue.directive('focus2', {
  inserted: (el) => {
    el.focus()
  }
})


/**
 * 防抖指令
 */
Vue.directive('debounce', {
  inserted: (el, binding) => {
    let timer;
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        binding.value();
      }, 500);
    })
  }
})

/**
 * 按钮权限指令
 */
Vue.directive('permission', {
  inserted: (el, binding) => {
    if (store.getters.getUserInfo.hrType !== 1) {
      el.style.display = 'none'
    }
  }
})
