var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.searchType === 'global' ||
      ((_vm.searchType === 'conversation' || (!_vm.searchType && _vm.isUniFrameWork)) &&
        _vm.isShowInConversationSearch)
  )?_c('div',{class:[
    'tui-search',
    !_vm.isPC && 'tui-search-h5',
    ("tui-search-main-" + (_vm.searchType ? _vm.searchType : 'conversation')),
    _vm.isFullScreen && 'tui-search-h5-full-screen' ]},[(_vm.searchType === 'global')?_c('div',{ref:"globalSearchRef",class:['tui-search-global', !_vm.isPC && 'tui-search-h5-global']},[_c('div',{class:[
        'tui-search-global-header',
        !_vm.isPC && 'tui-search-h5-global-header' ]},[_c('SearchInput',{staticClass:"search-input",attrs:{"searchType":_vm.searchType}}),(_vm.isPC || !_vm.searchingStatus)?_c('SearchMore',{staticClass:"search-more",attrs:{"searchType":_vm.searchType}}):_vm._e()],1),(_vm.searchingStatus)?_c('SearchContainer',{staticClass:"search-container",attrs:{"popupPosition":"bottom","searchType":_vm.searchType},scopedSlots:_vm._u([{key:"result",fn:function(){return [_c('SearchResult',{staticClass:"search-result",attrs:{"searchType":_vm.searchType}})]},proxy:true}],null,false,2895857728)}):_vm._e()],1):(
      (_vm.searchType === 'conversation' && _vm.isShowInConversationSearch) ||
        _vm.isUniFrameWork
    )?_c('div',{class:[
      'tui-search-conversation',
      !_vm.isPC && 'tui-search-h5-conversation' ]},[_c('SearchContainer',{staticClass:"search-container",attrs:{"popupPosition":"aside","searchType":_vm.searchType ? _vm.searchType : 'conversation'},on:{"closeInConversationSearch":_vm.closeInConversationSearch},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('SearchInput',{attrs:{"searchType":_vm.searchType ? _vm.searchType : 'conversation'}})]},proxy:true},{key:"result",fn:function(){return [_c('SearchResult',{staticClass:"search-result",attrs:{"searchType":_vm.searchType ? _vm.searchType : 'conversation'}})]},proxy:true}],null,false,1964603779)})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }