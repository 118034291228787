import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import {
  Aside,
  Container,
  Header,
  Row,
  Col,
  Main,
  Form,
  FormItem,
  Input,
  Button,
  Message,
  MessageBox,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Dialog,
  Upload,
  Progress,
  Select,
  Option,
  Image,
  TimeSelect,
  Checkbox,
  Tag,
  Card,
  InfiniteScroll,
  Tooltip,
  Backtop,
  Cascader,
  DatePicker,
  Popover,
  Table,
  TableColumn,
  RadioGroup,
  Radio,
  Pagination,
  Loading,
  CheckboxGroup,
  Calendar,
  TimePicker,
  Alert,
  PageHeader
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "swiper/dist/css/swiper.css";

Vue.use(VueAwesomeSwiper);
Vue.use(Aside);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Row);
Vue.use(Col);
Vue.use(Alert);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dialog);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Select);
Vue.use(Option);
Vue.use(Image);
Vue.use(TimeSelect);
Vue.use(Tag);
Vue.use(Card);
Vue.use(PageHeader);
Vue.use(InfiniteScroll);
Vue.use(Tooltip);
Vue.use(Backtop);
Vue.use(Cascader);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Loading)
Vue.use(CheckboxGroup)
Vue.use(Calendar)
Vue.use(TimePicker)
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
