export default {
  mapWebKey: 'e7c37ae0edbee391e2c06f40545df69d', // 高德地图webkey
  mapWebJsKey: 'e4544a8591e1b3cbbf94f204ec2987ca', // 高德地图webjskey
  goEasyKey: 'BC-bde1e617fe114ec191d4d0438a139b8a', // 聊天goeasy key（正式服）
  companyVideoTemplate: { // 公司视频模板
    coverUrl: 'https://yzzp.doncg.com/store/image/39b75e03-5701-47c3-8666-3d8b43f9b1b51698132187013',
    videoUrl: 'https://yzzp.doncg.com/store/image/e3cea2ef-5119-4bfc-a003-cbd5cc647d771698132190683',
  },
  positionVideoTemplate: { // 职位视频模板
    coverUrl: 'https://yzzp.doncg.com/store/image/39b75e03-5701-47c3-8666-3d8b43f9b1b51698132187013',
    videoUrl: 'https://yzzp.doncg.com/store/image/e3cea2ef-5119-4bfc-a003-cbd5cc647d771698132190683',
  },
  defaultAvatarList: [ // 默认头像列表
    'https://yzzp.doncg.com/store/92e1cf30-0801-49ea-9058-16829054b580avatar1-img.png',
    'https://yzzp.doncg.com/store/4b2f8725-e01e-4cab-825a-7958f6e6736eavatar2-img.png',
    'https://yzzp.doncg.com/store/d9bfdd37-add0-4395-bd75-dacde5bcca7aavatar3-img.png',
    'https://yzzp.doncg.com/store/d8eaa815-c3f1-42d5-a002-d6740ff788f2avatar4-img.png',
    'https://yzzp.doncg.com/store/11f91002-8384-454d-a690-dbfe07406f73avatar5-img.png',
    'https://yzzp.doncg.com/store/562f3566-1182-4384-975a-4450c6580d22avatar6-img.png',
    'https://yzzp.doncg.com/store/950688c4-a029-4b74-870a-e572e00c67afavatar7-img.png',
    'https://yzzp.doncg.com/store/00d12e52-22c7-4aff-b26a-6490181611edavatar8-img.png',
  ],
}