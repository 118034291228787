<template>
  <div class="layout-container">
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :style="{ width: menuWidth + 'px' }" ref="aside">
        <img class="logo" src="@/assets/images/common/logo-text-icon.png" alt="" />
        <div class="menu-list">
          <div class="menu-item" v-for="(item, index) in menuList" :key="index" @click="handleNavigation(item.path)">
            <img class="icon" :src="index == selectedMenuIndex ? item.selectedIcon : item.icon" alt="" />
            <p :class="['title', index == selectedMenuIndex ? 'active-title' : '']">
              {{ item.title }}
            </p>
          </div>
        </div>
        <div class="info-box">
          <template v-if="menuWidth > 70">
            <div class="abount-us">
              <el-tooltip popper-class="about-tooltip" placement="right" :visible-arrow="false">
                <p>关于我们</p>
                <div class="tips" slot="content">
                  成都立登科技有限公司，成立于2023年5月，是一家服务于游戏产业美术领域求职招聘的科技公司。
                  我们专注于游戏美术发展的研究，追踪全球CG行业的艺术趋势，报道最新最优的游戏产品与行业内杰出专家。此外，我们还分析和研究游戏企业的发展趋势及美术需求。
                  「立登科技」旨在为全体游戏美术行业从业人员、发行商、投资者以及产业链相关人士提供最新资讯、人才聚合、美术需求发布与解读。 让我们一道，为构建游戏行业内深度交流与合作的平台而努力！
                </div>
              </el-tooltip>
              <p class="divide">|</p>
              <el-tooltip popper-class="about-tooltip" placement="right" :visible-arrow="false">
                <p>经营范围</p>
                <div class="tips" slot="content">
                  一般项目:技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广，软件开发，数字文化创意软件开发，软件外包服务，网络与信息安全软件开发，动漫游戏开发信息技术咨询服务:数据处理和存储支持服务:信息咨询服务(不含许可类信息咨询服务)，计算机系统服务，网络技术服务，数字内容制作服务(不含出版发行):五联网销售(除销售需要许可的商品)，广告制作，平面设计，其他文化艺术经纪代理，数字文化创意内容应用服务，专业设计服务，广告设计、代理，文化娱乐经纪人服务，艺(美)术品、收藏品鉴定评估服务，市场营销策划，艺术品代理，玩具、动漫及游艺用品销售。服装服饰零售，针纺织品销售，人力资源服务
                  (不含职业中介活动、劳务派遭服务)、日用品销售，箱包销售;户外用品销售，钟表销售，礼品花卉销售。(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)
                  许可项目:网络文化经营，第一类增值电信业务:第二类增值电信亚务，出版物互联网销售:出版物批发，职业中介活动。(依法须批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准)
                </div>
              </el-tooltip>
            </div>
            <div class="contact-box">
              <p>客服电话：028-85097403</p>
              <p>联系地址：中国(四川)自由贸易试验区成都高新区德华路333号2栋6楼2号</p>
              <p>聘播播 @2023本站版权归成都立登科技有限公司所有</p>
              <p class="link" @click="handleOpenNewWindow">蜀ICP备2023007132号</p>
            </div>
          </template>
        </div>
      </el-aside>

      <el-container>
        <!-- 头部 -->
        <el-header :style="{ 'margin-left': menuWidth + 'px' }">
          <div class="header-content">
            <img class="logo-icon" src="@/assets/images/layout/header-text-bg.png" alt="" />
            <div class="left-box">
              <div class="message-number" @click="handleNavigation('/communicate')">
                <!-- <img class="icon" src="@/assets/images/layout/bell-icon.png" alt="" /> -->
                <p class="text">消息</p>
                <p class="number" v-if="getUreadMessageTotalCount > 0 && getUreadMessageTotalCount <= 9">{{ getUreadMessageTotalCount }}</p>
                <p class="number two" v-else-if="getUreadMessageTotalCount > 9 && getUreadMessageTotalCount <= 99">
                  {{ getUreadMessageTotalCount }}
                </p>
                <p class="number three" v-else-if="getUreadMessageTotalCount > 99">99+</p>
              </div>
              <p class="name" @click="showPreviewCompanyDialog = true">{{ getCompanyInfo.name }}·{{ getUserInfo.jobName }}</p>
              <img v-if="getUserInfo.vipType == 1" class="vip-icon" src="@/assets/images/layout/vip-icon.png" alt="" />
              <img v-else-if="getUserInfo.vipType == 2" class="vip-icon" src="@/assets/images/layout/svip-icon.png" alt="" />
              <el-dropdown size="small" @command="handleClickMenu" trigger="hover" placement="top">
                <span class="el-dropdown-link">
                  <el-image class="avatar" fit="cover" :src="getUserInfo.avatar"></el-image>
                </span>
                <el-dropdown-menu slot="dropdown" class="el-dorpdown-menu-person">
                  <el-dropdown-item command="personal">个人资料</el-dropdown-item>
                  <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-header>

        <!-- 内容 -->
        <el-main :style="{ 'margin-left': menuWidth + 'px' }">
          <div class="main-container" ref="messageContent" @scroll="handlePageScroll" @click="handlePageClick">
            <div class="main-content">
              <router-view />
              <!-- 滚动到顶部按钮 -->
              <el-backtop target=".main-container" :bottom="100">
                <img class="icon" src="@/assets//images/layout/backtop.png" alt="" />
              </el-backtop>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>

    <!-- 预览公司 -->
    <PreviewCompany v-if="showPreviewCompanyDialog" @cancel="showPreviewCompanyDialog = false" />

    <!-- 开通会员提示弹窗 -->
    <MemberTips v-if="showMemberTipsDialog" @confirm="handleOpenMember" @cancel="showMemberTipsDialog = false" />
  </div>
</template>

<script>
import { Notification } from 'element-ui'
import { mapGetters } from 'vuex'
import MemberTips from '@/components/MemberTips'
import { throttle } from '@/utils/tools'
import PreviewCompany from '@/components/PreviewCompany.vue'
import { getUserSig } from '@/api/user.js'
import { TUILogin } from "@tencentcloud/tui-core";
export default {
  name: 'layoutView',
  components: { PreviewCompany, MemberTips },
  data() {
    return {
      menuWidth: 70, // 侧边栏宽度
      showMemberTipsDialog: false, // 是否显示开通会员提示弹窗
      showPreviewCompanyDialog: false, // 是否显示预览公司弹窗
      menuList: [
        {
          title: '职位管理',
          path: '/position',
          icon: require('@/assets/images/layout/position-icon.png'),
          selectedIcon: require('@/assets/images/layout/position-active-icon.png'),
        },
        {
          title: '人才搜索',
          path: '/search',
          icon: require('@/assets/images/layout/search-icon.png'),
          selectedIcon: require('@/assets/images/layout/search-active-icon.png'),
        },
        {
          title: '人才包',
          path: '/package',
          icon: require('@/assets/images/layout/package-icon.png'),
          selectedIcon: require('@/assets/images/layout/package-active-icon.png'),
        },
        {
          title: '沟通',
          path: '/communicate',
          icon: require('@/assets/images/layout/communicate-icon.png'),
          selectedIcon: require('@/assets/images/layout/communicate-active-icon.png'),
        },
        {
          title: '面试',
          path: '/interview',
          icon: require('@/assets/images/layout/interview-icon.png'),
          selectedIcon: require('@/assets/images/layout/interview-active-icon.png'),
        },
        {
          title: '收到报名',
          path: '/sign',
          icon: require('@/assets/images/layout/sign-icon.png'),
          selectedIcon: require('@/assets/images/layout/sign-active-icon.png'),
        },
        {
          title: '人才库',
          path: '/talent',
          icon: require('@/assets/images/layout/talent-icon.png'),
          selectedIcon: require('@/assets/images/layout/talent-active-icon.png'),
        },
        {
          title: '服务管理',
          path: '/service',
          icon: require('@/assets/images/layout/service-icon.png'),
          selectedIcon: require('@/assets/images/layout/service-active-icon.png'),
        },
        {
          title: '权益商城',
          path: '/equity',
          icon: require('@/assets/images/layout/equity-icon.png'),
          selectedIcon: require('@/assets/images/layout/equity-active-icon.png'),
        },
        {
          title: '招聘数据',
          path: '/data',
          icon: require('@/assets/images/layout/data-icon.png'),
          selectedIcon: require('@/assets/images/layout/data-active-icon.png'),
        },
        {
          title: '公司主页',
          path: '/company',
          icon: require('@/assets/images/layout/company-icon.png'),
          selectedIcon: require('@/assets/images/layout/company-active-icon.png'),
        },
      ],
      selectedMenuIndex: 0, // 选中的菜单索引
      selectedMenuTitle: '', // 选中的菜单名称
    }
  },
  computed: {
    ...mapGetters(['getUserInfo', 'getCompanyInfo', 'getConversations', 'getUreadMessageTotalCount']),
  },
  watch: {
    // 监听路由变化激活菜单
    $route: {
      handler(to, from) {
        this.selectedMenuIndex = this.menuList.findIndex((item) => to.path.startsWith(item.path))
        this.selectedMenuTitle = to.meta.title
      },
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch('fetchUserBaseInfo')
    await this.$store.dispatch('fetchCompanyInfo')
    this.LoginIM()

    // 监听显示开通服务弹窗
    this.$eventBus.$on('showMemberTipsDialog', () => {
      this.showMemberTipsDialog = true
    })
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        let aside = this.$refs.aside.$el
        // 监听鼠标移入侧边栏
        aside.addEventListener('mouseenter', () => {
          this.menuWidth = 200
        })
        // 监听鼠标移出侧边栏
        aside.addEventListener('mouseleave', () => {
          this.menuWidth = 70
        })
      }, 1000)
    })
  },
  beforeDestroy() {
    // this.$goEasy.im.off(
    //   this.$GoEasy.IM_EVENT.CONVERSATIONS_UPDATED,
    //   this.listenConversationUpdate
    // );
  },
  methods: {
    /**
     * 监听页面滚动
     */
    handlePageScroll(e) {
      throttle(() => {
        this.$eventBus.$emit('pageScroll', e)
      })
    },

    /**
     * 监听页面点击
     */
    handlePageClick(e) {
      this.$eventBus.$emit('pageClick', e)
    },

    /**
     * 开通会员
     */
    handleOpenMember() {
      this.showMemberTipsDialog = false
      this.$router.push('/service')
    },
    /**
     * 连接goeasy
     */
    async LoginIM() {
      let { data } = await getUserSig({
        identifier: this.getUserInfo.userId + '-hr',
      })
      this.$store.commit('setSign', data)
      await this.$tim.login({
        userID: String(this.getUserInfo.userId) + '-hr',
        userSig: data,
      })

      setTimeout(() => {
        this.$tim
          .updateMyProfile({
            nick: this.getUserInfo.name,
            avatar: this.getUserInfo.avatar,
            profileCustomField: [
              {
                key: 'Tag_Profile_Custom_CMId',
                value: String(this.getCompanyInfo.companyId),
              },
              {
                key: 'Tag_Profile_Custom_CMName',
                value: this.getCompanyInfo.simpleName || this.getCompanyInfo.name,
              },
              {
                key: 'Tag_Profile_Custom_nickName',
                value: this.getUserInfo.jobName || '',
              },
              {
                key: 'Tag_Profile_Custom_Type',
                value: 'hr',
              },
            ],
          })
          .then((res) => {
            console.log(res.data, ' 更新资料成功') // 更新资料成功
            let promise = this.$tim.getMyProfile()
            promise
              .then(function (imResponse) {
                console.log(imResponse.data, ' 个人资料 - Profile 实例') // 个人资料 - Profile 实例
              })
              .catch(function (imError) {
                console.warn('getMyProfile error:', imError) // 获取个人资料失败的相关信息
              })
            // TUILogin.login({
            //   SDKAppID: process.env.VUE_APP_TIM_APPID,
            //   userID: String(this.getUserInfo.userId) + '-hr',
            //   userSig: data,
            //   // 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true  
            //   useUploadPlugin: true,
            //   useProfanityFilterPlugin: false,
            //   framework: "vue2",
            // });

          })
      }, 500)

      this.$tim.on(this.$TIM.EVENT.CONVERSATION_LIST_UPDATED, this.listenConversationUpdate)
    },

    /**
     * 加载会话列表
     */
    loadConversations() {
      this.$goEasy.im.latestConversations({
        onSuccess: async ({ content }) => {
          // 过滤hr用户
          content.unreadTotal = 0
          content.conversations = content.conversations.filter((item) => {
            if (item.data.type == 'user') {
              return item
            }
          })
          content.conversations.forEach((item) => {
            content.unreadTotal += item.unread
          })

          this.$store.commit('setUreadMessageTotalCount', content.unreadTotal)
          this.$store.commit('setConversations', content.conversations)
          // this.handleFindUserOnlineStatus();
          // this.subscribeUserPresence();
          console.log('会话列表：', content.conversations)
        },
        onFailed: (error) => {
          console.log('获取最新会话列表失败, code:' + error.code + 'content:' + error.content)
        },
      })
    },

    /**
     * 监听会话列表变化
     */
    async listenConversationUpdate(content) {
      // console.log(content.data)
      // 获取用户资料
      let { data } = await this.$tim.getUserProfile({
        userIDList: content.data.map((item) => item.userProfile.userID), // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
      })
      console.log(data, '用户信息')
      // 过滤hr用户
      let unreadTotal = 0
      content.data.map((item) => {
        let file = data.find((dItem) => item.userProfile.userID === dItem.userID)
        item.userProfile.profileCustomField = file.profileCustomField
        unreadTotal += item.unreadCount
      })

      this.$store.commit('setUreadMessageTotalCount', unreadTotal)
      this.$store.commit('setConversations', content.data)
      // this.handleFindUserOnlineStatus();
      // this.subscribeUserPresence();
      console.log('新会话列表：', content.data)
    },

    /**
     * 订阅推送
     */
    subscribeNotification() {
      this.$goEasy.pubsub.subscribe({
        channel: this.getUserInfo.userId.toString(),
        onMessage: function (message) {
          console.log('Channel:' + message.channel + ' content:' + message.content)
          Notification({
            title: '系统通知',
            message: message.content,
            type: 'info',
          })
        },
        onSuccess: function () {
          console.log('Subscribe successfully.')
        },
        onFailed: function () {
          console.log('Subscribe successfully.')
        },
      })
    },

    /**
     * 查询用户在线状态
     */
    handleFindUserOnlineStatus() {
      let conversations = [...this.getConversations]
      if (conversations.length == 0) return
      let userIds = conversations.map((item) => item.userID)
      this.$tim.getUserStatus({
        userIDList: userIds, //每次查询最多不超过50个
        onSuccess: ({ content }) => {
          // content.forEach((user) => {
          //   let index = conversations.findIndex(
          //     (item) => item.userId == user.id
          //   );
          //   if (index !== -1) {
          //     conversations[index]["online"] = true;
          //     this.$set(conversations, index, conversations[index]);
          //   }
          // });
          // this.$store.commit("setConversations", conversations);
          console.log('在线用户：', content)
        },
        onFailed: function (error) {
          //连接失败
          console.log(error)
        },
      })
    },

    /**
     * 订阅要监听的用户
     */
    subscribeUserPresence() {
      if (this.getConversations.length == 0) return
      let userIds = this.getConversations.map((item) => item.userId)
      this.$goEasy.im.subscribeUserPresence({
        userIds: userIds,
        onSuccess: function () {
          console.log('订阅监听用户成功')
        },
        onFailed: function (error) {
          //连接失败
          console.log('订阅监听用户失败, code:' + error.code + ' content:' + error.content)
        },
      })
    },

    /**
     * 监听用户在线状态
     */
    listenUserOnlineStatus(user) {
      let conversations = [...this.getConversations]
      let index
      index = conversations.findIndex((item) => item.userId == user.id)
      if (index !== -1) conversations[index].online = user.action == 'online' ? true : false
      this.$store.commit('setConversations', conversations)
      console.log('用户上下线：', user)
    },

    /**
     * 断开goeasy连接
     */
    logoutGoEasy() {
      try {
        console.log(22222222222)
        this.$tim.logout
          .then(() => {
            this.$store.commit('setConversations', [])
            this.$store.commit('setUreadMessageTotalCount', 0)
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * 点击菜单
     */
    handleClickMenu(type) {
      if (type == 'personal') {
        // 个人资料
        this.$router.push('/personal')
      } else if (type == 'logout') {
        // 退出登录
        this.$confirm('您确定要退出当前登录的账号吗?', '确认删除吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.logoutGoEasy()
            this.$store.dispatch('logout')
          })
          .catch(() => { })
      }
    },

    /**
     * 路由跳转
     */
    handleNavigation(path) {
      // 判断当前页面是否是沟通页面
      if (path == '/communicate' && this.$route.path.includes('/communicate')) return
      if (path == '/service' && this.$route.path == '/service') return this.$router.push({ name: 'refresh' })
      this.$router.push(path)
    },

    /**
     * 打开icp赔案网站
     */
    handleOpenNewWindow() {
      window.open('https://beian.miit.gov.cn/#/Integrated/recordQuery')
    },
  },
}
</script>
<style lang="less" scoped>
@header-height: 56px;

.layout-container {
  position: relative;

  .el-aside {
    transition: all 0.3s;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    background: url("@/assets/images/layout/aside-bg.png") no-repeat;
    background-size: 100% 100%;
    // background: #ffffff;

    display: flex;
    flex-direction: column;
    // align-items: center;
    overflow-x: hidden;
    padding-left: 32px;

    // &::-webkit-scrollbar {
    // width: 10px;
    // height: 10px;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: #b4b4b4;
    //   border-radius: 10px;
    // }

    // &::-webkit-scrollbar-track {
    //   background: #e7e7e7;
    // }

    .logo {
      // margin-top: 55px;
      margin-top: 15px;
      width: 120px;
      margin-left: -5px;
      margin-bottom: 30px;
    }

    .menu-list {
      // margin-top: 6vh;
      display: flex;
      flex-direction: column;

      .menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        cursor: pointer;
        width: 130px;

        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          width: 20px;
          margin-right: 18px;
        }

        .title {
          width: 100%;
          font-size: 20px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: #aaaaaa;
          transition: all 0.2s ease;
          width: 88px;
          text-align: justify;
          text-justify: distribute-all-lines;
          text-align-last: justify;

          &:hover {
            color: #8b8b8b;
          }
        }

        .active-title {
          color: #555555 !important;
        }
      }
    }

    .info-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 8px;
      margin-top: auto;
      margin-bottom: 16px;
      margin-left: -32px;
      height: 170px;
      overflow: hidden;

      .abount-us {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        p {
          font-size: 11px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: #bbbbbb;
          transition: all 0.3s ease;

          &:hover {
            color: #8b8b8b;
          }
        }

        .divide {
          padding: 0 10px;
        }
      }

      .contact-box {
        margin-top: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          font-size: 11px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #bbbbbb;
          text-align: center;
          line-height: 20px;
        }

        .link {
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            color: #8b8b8b;
          }
        }
      }
    }
  }

  .el-header {
    transition: all 0.3s;
    height: @header-height !important;
    background: url("@/assets/images/layout/header-bg.png") no-repeat;
    background-size: 100% 100%;
    // background: #d8d8d880;

    .header-content {
      margin: 0 auto;
      width: 1410px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .logo-icon {
        width: 195px;
        height: 13px;
      }

      .left-box {
        display: flex;
        flex-direction: row;
        align-items: center;

        .message-number {
          position: relative;
          cursor: pointer;

          .icon {
            width: 13px;
            height: 15px;
            margin-right: 16px;
            margin-top: 5px;
          }

          .text {
            font-size: 13px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #777777;
            cursor: pointer;
            margin-right: 16px;
            transition: all 0.3s;

            &:hover {
              color: #333333;
            }
          }

          .number {
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: 3px;
            position: absolute;
            width: 20px;
            height: 20px;
            text-align: center;
            background: #ff4949;
            border-radius: 50%;
            font-size: 11px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            transform: scale(0.7);
          }

          .two {
            border-radius: 200px;
            right: 0px;
            width: 25px;
          }

          .three {
            border-radius: 200px;
            right: -4px;
            width: 30px;
          }
        }

        .name {
          font-size: 13px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #777777;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            color: #333333;
          }
        }

        .vip-icon {
          margin-top: 1px;
          width: 35px;
          height: 17px;
          margin-left: 5px;
        }

        .avatar {
          margin-left: 15px;
          margin-top: 4px;
          width: 34px;
          height: 34px;
          background: #eeeeff;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }

  .el-main {
    transition: all 0.3s;
    padding: 0;

    .main-container {
      background: url("@/assets/images/layout/main-bg.png") no-repeat;
      background-size: 100% 100%;
      min-height: calc(100vh - @header-height);
      height: calc(100vh - @header-height);
      overflow-y: auto;

      // &::-webkit-scrollbar {
      // 	width: 9px;
      // 	height: 9px;
      // }

      // &::-webkit-scrollbar-thumb {
      // 	background: #a4a4a4;
      // 	border-radius: 10px;
      // }

      // &::-webkit-scrollbar-track {
      // 	background: #d0d0d0;
      // 	border-radius: 10px;
      // }

      .main-content {
        margin: 0 auto;
        width: 1410px;
        position: relative;

        /deep/ .el-backtop {
          width: 50px;
          height: 50px;

          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
