var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('SearchResultLoading',{class:['search-result-loading', !_vm.isPC && 'search-result-loading-h5']}):(_vm.isSearchDefaultShow)?_c('SearchResultDefault',{class:['search-result-default', !_vm.isPC && 'search-result-default-h5']}):_c('div',{class:[
    'tui-search-result',
    !_vm.isPC && 'tui-search-result-h5',
    _vm.isPC && _vm.isResultDetailShow && 'tui-search-result-with-border' ]},[(_vm.props.searchType !== 'conversation' && (_vm.isPC || !_vm.isResultDetailShow))?_c('div',{staticClass:"tui-search-result-main"},[_c('div',{staticClass:"tui-search-result-list"},_vm._l((_vm.searchResult),function(result){return _c('div',{key:result.key,staticClass:"tui-search-result-list-item"},[(_vm.props.searchType === 'global')?_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t(("TUISearch." + (result.label))))+" ")]):_vm._e(),_c('div',{staticClass:"list"},_vm._l((result.list),function(item){return _c('div',{key:item.conversation.conversationID,class:[_vm.generateListItemClass(item)]},[(result.key === 'contact' || result.key === 'group' || item.conversation)?_c('SearchResultItem',{attrs:{"listItem":item,"type":result.key,"displayType":"info","keywordList":_vm.keywordList},on:{"showResultDetail":_vm.showResultDetail,"navigateToChatPosition":_vm.navigateToChatPosition}}):_vm._e()],1)}),0),(_vm.currentSearchTabKey === 'all' || result.cursor)?_c('div',{staticClass:"more",on:{"click":function($event){return _vm.getMoreResult(result)}}},[_c('Icon',{staticClass:"more-icon",attrs:{"file":_vm.searchIcon,"width":"12px","height":"12px"}}),_c('div',{staticClass:"more-text"},[_c('span',[_vm._v(_vm._s(_vm.TUITranslateService.t("TUISearch.查看更多")))]),_c('span',[_vm._v(_vm._s(_vm.TUITranslateService.t(("TUISearch." + (result.label)))))])])],1):_vm._e()])}),0)]):_vm._e(),(_vm.isResultDetailShow || _vm.props.searchType === 'conversation')?_c('div',{class:[
      'tui-search-result-detail',
      _vm.props.searchType === 'conversation' && 'tui-search-result-in-conversation' ]},[(_vm.isSearchDetailLoading)?_c('SearchResultLoading',{class:['search-result-loading', !_vm.isPC && 'search-result-loading-h5']}):_vm._e(),(!_vm.isSearchDetailLoading && _vm.isResultDetailShow && _vm.props.searchType !== 'conversation')?_c('div',{staticClass:"tui-search-message-header"},[_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"header-content-count normal"},[_c('span',[_vm._v(_vm._s(_vm.searchConversationMessageTotalCount))]),_c('span',[_vm._v(_vm._s(_vm.TUITranslateService.t("TUISearch.条与")))])]),_c('div',{staticClass:"header-content-keyword"},_vm._l((_vm.keywordList),function(keyword,index){return _c('span',{key:index},[_c('span',{staticClass:"normal"},[_vm._v("\"")]),_c('span',{staticClass:"highlight"},[_vm._v(_vm._s(keyword))]),_c('span',{staticClass:"normal"},[_vm._v("\"")])])}),0),_c('div',{staticClass:"header-content-type normal"},[_c('span',[_vm._v(_vm._s(_vm.TUITranslateService.t("TUISearch.相关的")))]),_c('span',[_vm._v(_vm._s(_vm.TUITranslateService.t( ("TUISearch." + (_vm.currentSearchTabKey === "allMessage" ? "结果" : _vm.searchMessageTypeList[_vm.currentSearchTabKey].label)) )))])])]),_c('div',{staticClass:"header-enter",on:{"click":function($event){return _vm.enterConversation({ conversationID: _vm.currentSearchConversationID })}}},[_c('span',[_vm._v(_vm._s(_vm.TUITranslateService.t("TUISearch.进入聊天")))]),_c('Icon',{staticClass:"enter-icon",attrs:{"file":_vm.enterIcon,"width":"14px","height":"14px"}})],1)]):_vm._e(),(!_vm.isSearchDetailLoading &&
        _vm.searchConversationMessageList &&
        _vm.searchConversationMessageList[0]
      )?_c('div',{staticClass:"tui-search-message-list"},[(_vm.props.searchType === 'global' ||
          (_vm.currentSearchTabKey !== 'imageMessage' && _vm.currentSearchTabKey !== 'fileMessage')
        )?_vm._l((_vm.searchConversationMessageList),function(item){return _c('div',{key:_vm.generateVueRenderKey(item.ID),class:['list-item']},[_c('SearchResultItem',{attrs:{"listItem":item,"listItemContent":item.getMessageContent(),"type":_vm.currentSearchTabKey,"displayType":_vm.generateResultItemDisplayType(),"keywordList":_vm.keywordList},on:{"showResultDetail":_vm.showResultDetail,"navigateToChatPosition":_vm.navigateToChatPosition}})],1)}):_vm._l((_vm.searchConversationResultGroupByDate),function(group){return _c('div',{key:_vm.generateVueRenderKey(group.date),class:['list-group', _vm.currentSearchTabKey === 'fileMessage'? 'list-group-file' : 'list-group-image']},[_c('div',{class:['list-group-date']},[_vm._v(" "+_vm._s(group.date)+" ")]),_vm._l((group.list),function(item){return _c('div',{key:_vm.generateVueRenderKey(item.ID),class:['list-group-item']},[_c('SearchResultItem',{attrs:{"listItem":item,"listItemContent":item.getMessageContent(),"type":_vm.currentSearchTabKey,"displayType":_vm.generateResultItemDisplayType(),"keywordList":_vm.keywordList},on:{"showResultDetail":_vm.showResultDetail,"navigateToChatPosition":_vm.navigateToChatPosition}})],1)})],2)}),(_vm.searchConversationResult && _vm.searchConversationResult.cursor)?_c('div',{staticClass:"more",on:{"click":_vm.getMoreResultInConversation}},[_c('Icon',{staticClass:"more-icon",attrs:{"file":_vm.searchIcon,"width":"12px","height":"12px"}}),_c('div',{staticClass:"more-text"},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t("TUISearch.查看更多历史记录"))+" ")])],1):_vm._e()],2):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }