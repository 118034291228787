import request from '@/utils/request';

/**
 * 获取公司信息
 */
export const getCompanyInfo = data => {
  return request({
    url: '/web/enterprise/company/info',
    method: 'post',
    data
  });
};

/**
 * 获取直播类型列表
 */
export const getLiveTypeList = data => {
  return request({
    url: '/web/system/liveType/list',
    method: 'post',
    data
  });
};

/**
 * 获取公司类型列表
 */
export const getCompanyTypeList = data => {
  return request({
    url: '/web/system/companyType/list',
    method: 'post',
    data
  });
};

/**
 * 获取风格列表
 */
export const getStyleList = data => {
  return request({
    url: '/web/system/style/treeList',
    method: 'post',
    data
  });
};

/**
 * 获取公司福利列表
 */
export const getCompanyTreatmentList = data => {
  return request({
    url: '/web/enterprise/company/welfare/list',
    method: 'post',
    data
  });
};

/**
 * 获取公司福利池列表
 */
export const getCompanyTreatmentPoolList = data => {
  return request({
    url: '/web/enterprise/system/welfare/list',
    method: 'post',
    data
  });
};

/**
 * 获取公司地址列表
 */
export const getCompanyAddressList = data => {
  return request({
    url: '/web/enterprise/company/address/list',
    method: 'post',
    data
  });
};

/**
 * 修改公司基本信息
 */
export const updateCompanyBaseInfo = data => {
  return request({
    url: '/web/enterprise/company/basic/update',
    method: 'post',
    data
  });
};

/**
 * 修改公司介绍
 */
export const updateCompanyIntroduce = data => {
  return request({
    url: '/web/enterprise/company/intro/update',
    method: 'post',
    data
  });
};

/**
 * 修改公司业务
 */
export const updateCompanyBusiness = data => {
  return request({
    url: '/web/enterprise/company/business/update',
    method: 'post',
    data
  });
};

/**
 * 修改公司相册
 */
export const updateCompanyAlbum = data => {
  return request({
    url: '/web/enterprise/company/photo/update',
    method: 'post',
    data
  });
};

/**
 * 修改公司福利
 */
export const updateCompanyTreatment = data => {
  return request({
    url: '/web/enterprise/company/welfare/update',
    method: 'post',
    data
  });
};

/**
 * 添加公司地址
 */
export const addCompanyAddress = data => {
  return request({
    url: '/web/enterprise/company/address/create',
    method: 'post',
    data
  });
};

/**
 * 删除公司地址
 */
export const deleteCompanyAddress = data => {
  return request({
    url: '/web/enterprise/company/address/delete',
    method: 'post',
    data
  });
};

/**
 * 关注用户
 */
export const followUser = data => {
  return request({
    url: '/web/enterprise/company/followAdd',
    method: 'post',
    data
  });
};

/**
 * 取消关注用户
 */
export const cancelFollowUser = data => {
  return request({
    url: '/web/enterprise/company/followCancel',
    method: 'post',
    data
  });
};

/**
 * 收藏用户
 */
export const collectUser = data => {
  return request({
    url: '/web/enterprise/user/collection/operation',
    method: 'post',
    data
  });
};

/**
 * 取消收藏用户
 */
export const cancelCollectUser = data => {
  return request({
    url: '/web/enterprise/user/collection/operation',
    method: 'post',
    data
  });
};

/**
 * 子账户列表
 */
export const getAccountList = data => {
  return request({
    url: '/web/enterprise/company/hr/list',
    method: 'post',
    data
  });
};

/**
 * 子账户删除
 */
export const deleteAccount = data => {
  return request({
    url: '/web/enterprise/company/hr/delete',
    method: 'post',
    data
  });
};

/**
 * 子账户添加
 */
export const addAccount = data => {
  return request({
    url: '/web/enterprise/company/hr/add',
    method: 'post',
    data
  });
};

/**
 * 子账户修改
 */
export const editAccount = data => {
  return request({
    url: '/web/enterprise/company/hr/update',
    method: 'post',
    data
  });
};

/**
 * 获取公司招聘数据
 */
export const getCompanyData = data => {
  return request({
    url: '/web/enterprise/company/data/detail',
    method: 'post',
    data
  });
};

/**
 * 获取公司hr招聘数据
 */
export const getCompanyHrData = data => {
  return request({
    url: '/web/enterprise/company/hr/data/list',
    method: 'post',
    data
  });
};

/**
 * 注册公司
 */
export const registerCompany = data => {
  return request({
    url: '/web/enterprise/company/register',
    method: 'post',
    data
  });
};

/**
 * 修改注册公司
 */
export const updateRegisterCompany = data => {
  return request({
    url: '/web/enterprise/company/register/update',
    method: 'post',
    data
  });
};

