import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Message } from "element-ui";
import { Loading } from "element-ui";

let loadingInstance;

const showMessage = Symbol("showMessage");

class DonMessage {
  success(options, single = true) {
    this[showMessage]("success", options, single);
  }

  warning(options, single = true) {
    this[showMessage]("warning", options, single);
  }

  info(options, single = true) {
    this[showMessage]("info", options, single);
  }

  error(options, single = true) {
    this[showMessage]("error", options, single);
  }

  [showMessage](type, options, single) {
    if (single) {
      // 判断是否已存在Message
      if (document.getElementsByClassName("el-message").length === 0) {
        Message[type](options);
      }
    } else {
      Message[type](options);
    }
  }
}
let MessageOnce = new DonMessage();

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 请求地址
  timeout: 50000, // 请求超时时间
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // loadingInstance = Loading.service({
    //   lock: true,
    //   text: '加载中...',
    //   spinner: 'el-icon-loading',
    //   background: 'rgba(0, 0, 0, 0.7)',
    // });
    if (store.getters.getToken && !(config.auth === false)) {
      config.headers["Authorization"] = "Bearer "+ store.getters.getToken;
    } else {
      config.headers["Authorization"] = "Basic bWFsbC1oci1hZG1pbjoxMjM0NTY=";
    }
    config.headers["Site"] = "web";
    return config;
  },
  (error) => {
    // loadingInstance.close();
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // loadingInstance.close();
    const res = response.data;
    if (res.code !== 200) {
      if (res.code === 4030) return Promise.reject(res);
      //标识套餐购买超出限制
      if (res.code === 4031) return Promise.reject(res);
      // 统一错误消息提示
      MessageOnce.error(res.msg);
      console.log(res.msg)
      if (res.code === 401) {
        // 登录过期
        setTimeout(() => {
          store.dispatch("logout", false);
        }, 1500);
      } else if (res.code === 402) {
        // 用户不存在
        setTimeout(() => {
          store.dispatch("logout", false);
        }, 1500);
      }
      return Promise.reject(res);
    }

    // 返回成功的数据
    return Promise.resolve(res);
  },
  (error) => {
    // loadingInstance.close();
    MessageOnce.error(error.message);
    return Promise.reject(error);
  }
);

export default request;
