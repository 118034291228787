<template>
  <div class="confirm-box-container">
    <el-dialog :visible="true" width="780px" top="15vh" :before-close="handleCancle">
      <img class="img" src="@/assets/images/common/member-tips-img.png" alt="" />
      <div class="btn actived-btn btn-hover" @click="handleConfirm">立即升级</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmBox',
  props: {},
  methods: {
    /**
     * 关闭取消
     */
    handleCancle() {
      this.$emit('cancel')
    },

    /**
     * 点击确定
     */
    handleConfirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style lang="less" scoped>
.confirm-box-container {
  /deep/ .el-dialog__headerbtn {
    top: 0px;
    right: 9px;
    font-size: 20px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .el-dialog__footer {
    display: none;
  }

  .img {
    margin-top: -7px;
    width: 100%;
    height: auto;
  }

  .btn {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    width: 230px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #323741;
    border: 1px solid #d8d8d8;
    margin-right: 19px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 296px;

    &:hover {
      background-color: #e3ebff;
    }
  }

  .actived-btn {
    border: none;
    color: #ffffff;
    border-radius: 296px;
    background: linear-gradient(134deg, #66d4ff 0%, #3971ff 100%);
    box-shadow: 0px 11px 18px -10px rgba(0, 49, 123, 0.4);
  }
}
</style>
