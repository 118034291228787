import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import {
  Message,
  Notification
} from 'element-ui'
import {
  logout,
  logoff
} from '@/api/user'
import {
  getUserBaseInfo
} from '@/api/personal'
import {
  getCompanyInfo
} from '@/api/company'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '', // token
    userInfo: '', // 用户信息
    companyInfo: '', // 公司信息
    currentChartUserId: '', // 当前聊天用户id
    currentConversationID: '', // 当前聊天的会话id
    companyAuditStatus: '', // 企业审核状态
    conversations: '', // 会话列表
    ureadMessageTotalCount: 0, // 未读的消息总数,
    sign: '', //腾讯im签名
  },
  getters: {
    // 获取token
    getToken: (state) => state.token || JSON.parse(localStorage.getItem('token')),
    // 获取腾讯im签名
    getSign: (state) => state.sign || localStorage.getItem('sign'),
    // 获取用户信息
    getUserInfo: (state) => state.userInfo || JSON.parse(localStorage.getItem('userInfo')),
    // 获取公司信息
    getCompanyInfo: (state) => state.companyInfo || JSON.parse(localStorage.getItem('companyInfo')),
    // 获取当前聊天用户id
    getCurrentChartUserId: (state) => state.currentChartUserId || JSON.parse(localStorage.getItem('currentChartUserId')),
    // 获取当前聊天的会话id
    getCurrentConversationID: (state) => state.currentConversationID || JSON.parse(localStorage.getItem('currentConversationID')),
    // 获取企业审核状态
    getCompanyAuditStatus: (state) => state.companyAuditStatus || JSON.parse(localStorage.getItem('companyAuditStatus')),
    // 获取会话列表
    getConversations: (state) => state.conversations || JSON.parse(localStorage.getItem('conversations')),
    // 获取未读的消息总数
    getUreadMessageTotalCount: (state) => state.ureadMessageTotalCount || JSON.parse(localStorage.getItem('ureadMessageTotalCount')),
  },
  mutations: {
    /**
     * 设置token
     */
    setToken: (state, token) => {
      state.token = token
      localStorage.setItem('token', JSON.stringify(token))
    },
    /**
   * 设置SIGN
   */
    setSign: (state, sign) => {
      state.sign = sign
      localStorage.setItem('sign', sign)
    },
    /**
     * 设置用户信息
     */
    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },

    /**
     * 设置公司信息
     */
    setCompanyInfo: (state, companyInfo) => {
      state.companyInfo = companyInfo
      localStorage.setItem('companyInfo', JSON.stringify(companyInfo))
    },

    /**
     * 设置当前聊天用户id
     */
    setCurrentChartUserId: (state, currentChartUserId) => {
      state.currentChartUserId = currentChartUserId
      localStorage.setItem('currentChartUserId', JSON.stringify(currentChartUserId))
    },

    /**
     * 设置当前聊天会话id
     */
    setCurrentConversationID: (state, conversationID) => {
      state.currentConversationID = conversationID
      localStorage.setItem('currentConversationID', JSON.stringify(conversationID))
    },

    /**
     * 设置企业审核状态
     */
    setCompanyAuditStatus: (state, companyAuditStatus) => {
      state.companyAuditStatus = companyAuditStatus
      localStorage.setItem('companyAuditStatus', JSON.stringify(companyAuditStatus))
    },

    /**
     * 设置会话列表
     */
    setConversations(state, conversations) {
      state.conversations = conversations
      localStorage.setItem('conversations', JSON.stringify(conversations))
    },

    /**
     * 设置未读的消息总数
     */
    setUreadMessageTotalCount(state, ureadMessageTotalCount) {
      state.ureadMessageTotalCount = ureadMessageTotalCount
      localStorage.setItem('ureadMessageTotalCount', JSON.stringify(ureadMessageTotalCount))
    },
  },
  actions: {
    /**
     * 登录
     */
    login: async ({
      commit,
      dispatch
    }, data) => {
      commit('setToken', data.token)
      commit('setUserInfo', data)
      if (data.isAuthentication) {
        Message.success('登录成功')
        router.push('/position')
      } else {
        // 未实名认证
        router.push('/authentication')
      }
    },

    /**
     * 退出登录
     */
    logout: async ({
      commit
    }, result = true) => {
      if (result) await logout()
      // 清空登录信息
      commit('setToken', '')
      commit('setUserInfo', '')
      commit('setCompanyInfo', '')
      commit('setCurrentChartUserId', '')
      commit('setCurrentConversationID', '')
      commit("setConversations", '');
      commit("setUreadMessageTotalCount", 0);
      window.location.reload()
    },

    /**
     * 注销用户
     */
    logoff: async () => {
      // 清空登录信息
      await logoff()
      commit('setToken', '')
      commit('setUserInfo', '')
      commit('setCompanyInfo', '')
      commit('setCurrentChartUserId', '')
      commit('setCurrentConversationID', '')
      commit("setConversations", '');
      commit("setUreadMessageTotalCount", 0);
      window.location.reload()
    },

    /**
     * 获取企业信息
     */
    async fetchCompanyInfo({
      getters,
      commit
    }) {
      let {
        data
      } = await getCompanyInfo({})

      // 企业审核状态提示
      if (data.auditStatus == 0 && getters.getCompanyAuditStatus != 0) {
        // 待审核
        Notification({
          title: '企业信息审核中',
          message: '企业信息审核中，请耐心等待',
          type: 'info',
        })
      } else if (data.auditStatus == 1 && getters.getCompanyAuditStatus != 1) {
        // 审核通过
        Notification({
          title: '企业信息审核通过',
          message: '企业信息审核通过',
          type: 'success',
        })
      } else if (data.auditStatus == 2 && getters.getCompanyAuditStatus != 2) {
        // 审核拒绝
        Notification({
          title: '企业信息审核失败',
          message: data.auditReason,
          type: 'error',
        })
      } else if (data.auditStatus == 99 && getters.getCompanyAuditStatus != 99) {
        // 未完善企业信息
        Notification({
          title: '企业信息未完善',
          message: data.companyNoCompleteInfo,
          type: 'warning',
        })
      }
      commit('setCompanyInfo', data)
      commit('setCompanyAuditStatus', data.auditStatus)
    },

    /**
     * 获取用户信息
     */
    async fetchUserBaseInfo({
      getters,
      commit
    }) {
      let {
        data
      } = await getUserBaseInfo({})
      let {
        avatar,
        jobName,
        name,
        sex
      } = data
      commit('setUserInfo', {
        ...getters.getUserInfo,
        avatar,
        name,
        jobName,
        sex
      })
    },
  },
})