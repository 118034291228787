<template>
  <div
    v-if="isNotNetwork"
    class="network"
  >
    <i class="icon icon-error">!</i>
    <p class="network-content">
      {{
        TUITranslateService.t("TUIConversation.网络异常，请您检查网络设置")
      }}
    </p>
  </div>
</template>
<script lang="ts">
import TUIChatEngine, { TUIStore, StoreName, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../../adapter-vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const isNotNetwork = ref(false);
  TUIStore.watch(StoreName.USER, {
    netStateChange: (value: string) => {
      isNotNetwork.value = value === TUIChatEngine.TYPES.NET_STATE_DISCONNECTED;
    }
  });
  return {
    TUITranslateService,
    isNotNetwork
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped src="../style/index.scss"></style>
