var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Overlay',{ref:"overlayDomInstanceRef",attrs:{"visible":_vm.props.visible,"maskColor":_vm.props.overlayColor,"isFullScreen":_vm.props.isFullScreen},on:{"onOverlayClick":_vm.onOverlayClick}},[(_vm.isDrawerShow)?_c('div',{ref:"drawerDomRef",class:{
      'drawer': true,
      'origin-bottom': _vm.props.popDirection === 'bottom',
      'origin-right': _vm.props.popDirection === 'right',
      'slide-bottom': _vm.visible && _vm.props.popDirection === 'bottom',
      'slide-right': _vm.visible && _vm.props.popDirection === 'right',
    },style:({
      minHeight: _vm.styles.minHeight,
      maxHeight: _vm.styles.maxHeight,
      borderRadius: _vm.styles.borderRadius,
      boxShadow: _vm.styles.boxShadow,
      width: _vm.styles.width,
    })},[_c('div',{staticClass:"drawer-container"},[_vm._t("default")],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }