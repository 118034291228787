import Vue from "vue";
import { getUserPowerList } from '@/api/user'

let _debounceTimeout = null,
  _throttleRunning = false

/**
 * 防抖
 * @param {Function} 执行函数
 * @param {Number} delay 延时ms   
 */
export const debounce = (fn, delay = 500) => {
  clearTimeout(_debounceTimeout);
  _debounceTimeout = setTimeout(() => {
    fn();
  }, delay);
}

/**
 * 节流
 * @param {Function} 执行函数
 * @param {Number} delay 延时ms  
 */
export const throttle = (fn, delay = 500) => {
  if (_throttleRunning) {
    return;
  }
  _throttleRunning = true;
  fn();
  setTimeout(() => {
    _throttleRunning = false;
  }, delay);
}

/**
 * 校验功能权限
 * @param {*} fn 执行函数
 * @param {*} code 权限代码 1:职位发布, 2:智能邀约, 3:子账号管理, 4:打招呼, 5:开屏广告, 6职位曝光, 7企业曝光, 8定向人才推送, 9精准人才推送, 10急招发布, 11筛选, 12:简历, 13:到面
 * @param {*} data 函数参数
 */
export const verifyPermission = async (fn, code, ...data) => {
  let userPower = []
  let res = await getUserPowerList({})
  if (res.code == 200) userPower = res.data
  let permission = userPower.find(item => item.functionType == code)
  if (permission) {
    // 拥有权限
    if (permission.surplusCount == 0) {
      // 权限已用完
      Vue.prototype.$eventBus.$emit('showMemberTipsDialog')
    } else {
      // 权限未用完
      fn(...data)
    }
  } else {
    // 未拥有权限
    Vue.prototype.$eventBus.$emit('showMemberTipsDialog')
  }
}