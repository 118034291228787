<template>
  <div class="preview-video-container">
    <!-- 预览视频弹窗 -->
    <el-dialog title="视频预览" :visible="true" top="60px" custom-class="preview-video-dialog" :before-close="handleCancle">
      <video class="video" autoplay controls preload="auto" width="100%" :poster="videoInfo.coverUrl" :src="videoInfo.videoUrl"></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'PriviewVideo',
  props: {
    videoInfo: {
      // 视频信息
      type: Object,
      default: () => {},
    },
  },
  methods: {
    /**
     * 关闭取消
     */
    handleCancle() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="less" scoped>
.preview-video-container {
  /deep/ .el-dialog__wrapper {
    overflow-x: hidden;
    backdrop-filter: blur(30px);
  }

  // 预览视频弹窗
  /deep/ .preview-video-dialog {
    width: 1200px;
    border-radius: 20px;
    overflow: hidden;
    background: #000000;
    // margin-left: calc(50% - 280px);
    // transform: translateX(-21.5%);

    .el-dialog__title {
      font-size: 17px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: 400;
      color: #7e8189;
    }

    // 填写须知弹窗
    .el-dialog__header {
      padding: 9px 0;
      text-align: center;
    }

    .el-dialog__headerbtn {
      top: 9px;
      right: 9px;
      font-size: 24px;
    }

    .el-dialog__close {
      color: #7e8189 !important;
      transition: all 0.3s !important;

      &:hover {
        color: #ffffff !important;
      }
    }

    .el-dialog__body {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #1c1e22;

      .video {
        width: 100%;
        height: calc(100vh - 170px);
      }
    }

    .el-dialog__footer {
      padding: 0;
    }
  }
}
</style>
