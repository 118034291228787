import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import NProgress from 'nprogress'

import Layout from '@/layout/index'
import CompanyLayout from '@/views/company/index'
import PostLayout from '@/views/position/index'


// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'LoginView',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/index.vue'),
  },
  // {
  //   path: '/register',
  //   name: 'RegisterView',
  //   meta: {
  //     title: '注册',
  //   },
  //   component: () => import('@/views/register/index.vue'),
  // },
  {
    path: '/authentication',
    name: 'AuthenticationView',
    meta: {
      title: '实名认证',
    },
    component: () => import('@/views/authentication/index.vue'),
  },
  {
    path: '/SittingCustomerService',
    name: 'SittingCustomerService',
    meta: {
      title: '客服',
    },
    component: () => import('@/views/SittingCustomerService/SittingCustomerService.vue'),
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/company',
      meta: {
        title: '公司主页',
      },
      component: CompanyLayout,
      children: [
        {
          path: '/',
          redirect: '/company/info'
        },
        {
          path: 'info',
          name: 'InfoView',
          meta: {
            title: '公司基本信息',
          },
          component: () => import('@/views/company/info.vue'),
        },
        {
          path: 'introduce',
          name: 'IntroduceView',
          meta: {
            title: '公司介绍',
          },
          component: () => import('@/views/company/introduce.vue'),
        },
        {
          path: 'business',
          name: 'BusinessView',
          meta: {
            title: '公司业务',
          },
          component: () => import('@/views/company/business.vue'),
        },
        {
          path: 'photo',
          name: 'PhotoView',
          meta: {
            title: '公司相册',
          },
          component: () => import('@/views/company/photo.vue'),
        },
        {
          path: 'treatment',
          name: 'TreatmentView',
          meta: {
            title: '公司福利',
          },
          component: () => import('@/views/company/treatment.vue'),
        },
        {
          path: 'account',
          name: 'AccountView',
          meta: {
            title: '公司福利',
          },
          component: () => import('@/views/company/account.vue'),
        }
      ]
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/position/publish',
        name: 'PublishView',
        meta: {
          title: '发布职位',
        },
        component: () => import('@/views/position/publish.vue'),
      },
      {
        path: '/position',
        name: 'PostView',
        meta: {
          title: '职位管理',
        },
        component: PostLayout,
        children: [
          {
            path: '/',
            name: 'PositionView',
            meta: {
              title: '职位列表',
            },
            component: () => import('@/views/position/position.vue'),
          },
          {
            path: 'resume',
            name: 'ResumeView',
            meta: {
              title: '简历列表',
            },
            component: () => import('@/views/position/resume.vue'),
          }
        ],
      }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/package',
      name: 'PackageView',
      meta: {
        title: '人才包',
      },
      component: () => import('@/views/package/index')
    },
    {
      path: '/package/checkPackage',
      name: 'CheckPackageView',
      meta: {
        title: '查看人才包',
      },
      component: () => import('@/views/package/checkPackage')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/search',
      name: 'SearchView',
      meta: {
        title: '人才搜索',
      },
      component: () => import('@/views/search/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/communicate',
      name: 'CommunicateView',
      meta: {
        title: '沟通',
      },
      component: () => import('@/views/communicate/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/interview',
      name: 'InterviewView',
      meta: {
        title: '面试',
      },
      component: () => import('@/views/interview/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/sign',
      name: 'Sign',
      meta: {
        title: '收到报名',
      },
      component: () => import('@/views/sign/index')
    }, {
      path: '/viewSign',
      name: 'ViewSign',
      meta: {
        title: '查看报名',
      },
      component: () => import('@/views/sign/viewSign')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/data',
      name: 'DataView',
      meta: {
        title: '招聘数据',
      },
      component: () => import('@/views/data/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/talent',
      name: 'TalentView',
      meta: {
        title: '人才库',
      },
      component: () => import('@/views/talent/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/service',
      name: 'ServiceView',
      meta: {
        title: '服务管理',
      },
      component: () => import('@/views/service/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/equity',
      name: 'EquityView',
      meta: {
        title: '权益商城',
      },
      component: () => import('@/views/equity/index')
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/personal',
      name: 'PersonalView',
      meta: {
        title: '个人资料',
      },
      component: () => import('@/views/personal/index')
    }]
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('@/views/service/refresh.vue')
  },
  {
    path: '*',
    name: 'ErrorView',
    meta: {
      title: "errorView"
    },
    component: () => import('@/views/error/index.vue')
  },
];


// 跳转路由后滚动到顶部
const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 })
});


// 路由白名单
const whiteList = ['/login', '/register', '/authentication']
// 路由拦截器
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const userInfo = store.getters.getUserInfo || {}

  // 如果登录了在跳转到登录页面，则返回首页
  if (to.path === '/login' && userInfo.isAuthentication) {
    next('/search')
  }

  // 如果登录了在跳转到实名认证，则返回首页
  if (to.path === '/authentication' && userInfo.isAuthentication) {
    next('/search')
  }

  // 如果未登录在跳转到实名认证，则返回登录
  if (to.path === '/authentication' && !userInfo.token) {
    next('/')
  }

  // 判断是否在白名单
  const index = whiteList.findIndex(item => item === to.path)
  if (index !== -1) {
    next()
  } else {
    if (userInfo.isAuthentication) {
      next()
    } else {
      // 未登录
      next('/')
    }
  }
  NProgress.done();
})

export default router;
