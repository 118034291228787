var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'tui-search-input-container',
    !_vm.isPC && 'tui-search-input-container-h5',
    _vm.props.searchType === 'global'
      ? 'tui-search-input-container-global'
      : 'tui-search-input-container-conversation'
  ]},[_c('div',{class:['tui-search-input', !_vm.isPC && 'tui-search-input-h5']},[_c('div',{staticClass:"tui-search-input-left"},[_c('Icon',{staticClass:"icon",attrs:{"file":_vm.searchIcon,"width":"14px","height":"14px"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValueModel),expression:"searchValueModel"}],staticClass:"tui-search-input-main",attrs:{"type":"text","placeholder":_vm.props.placeholder,"focus":false,"enterkeyhint":"search"},domProps:{"value":(_vm.searchValueModel)},on:{"blur":_vm.onBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"confirm":_vm.search,"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSearchInputClick.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchValueModel=$event.target.value}}}),(_vm.searchingStatus)?_c('div',{staticClass:"tui-search-input-right",on:{"click":_vm.endSearching}},[_c('Icon',{staticClass:"icon",attrs:{"file":_vm.closeIcon,"width":"14px","height":"14px"}})],1):_vm._e()]),(!_vm.isPC && _vm.searchingStatus && _vm.props.searchType === 'global')?_c('div',{class:[
      'tui-search-input-cancel',
      !_vm.isPC && 'tui-search-input-h5-cancel' ],on:{"click":_vm.endSearching}},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t("TUISearch.取消"))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }