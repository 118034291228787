import request from '@/utils/request';

/**
 * 获取用户基本信息
 */
export const getUserBaseInfo = data => {
    return request({
        url: '/web/enterprise/user/getPersonalData',
        method: 'post',
        data
    });
};

/**
 * 修改用户基本信息
 */
export const updateUserBaseInfo = data => {
    return request({
        url: '/web/enterprise/user/info/update',
        method: 'post',
        data
    });
};