import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./utils/config";
import { debounce, throttle, verifyPermission } from "./utils/tools";
import { TUIComponents, TUIChatKit } from "./TUIKit";
import VueCompositionAPI from "@vue/composition-api";

import "./utils/element-ui";
import "./utils/filter";
import "./utils/directive";
import "@/assets/css/index.less";
import "./utils/progress";
// 腾讯IM相关 
import TIM from "@tencentcloud/chat";
import TIMUploadPlugin from "tim-upload-plugin";

const options = {
  SDKAppID: process.env.VUE_APP_TIM_APPID, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};

TUIChatKit.components(TUIComponents, Vue);
TUIChatKit.init();
// 创建 SDK 实例，
const tim = TIM.create(options);

tim.setLogLevel(0);

// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });

Vue.prototype.$tim = tim;
Vue.prototype.$TIM = TIM;
Vue.config.productionTip = false;
Vue.prototype.$config = config;
Vue.prototype.$debounce = debounce;
Vue.prototype.$throttle = throttle;
Vue.prototype.$verifyPermission = verifyPermission;
Vue.prototype.$eventBus = new Vue();
Vue.use(VueCompositionAPI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
