<template>
  <div class="message-box-container">
    <!-- 公司信息填写须知弹窗 -->
    <el-dialog :title="title" :visible="true" :width="width" top="30vh" :before-close="handleConfirm">
      <div class="tips">
        <slot></slot>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn btn-hover" @click="handleConfirm">我知道了</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'MessageBox',
  props: {
    width: {
      // 宽度
      type: String,
      default: '500px',
    },
    title: {
      // 标题
      type: String,
      default: '提示',
    },
  },
  methods: {
    /**
     * 点击确定
     */
    handleConfirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style lang="less" scoped>
.message-box-container {
  /deep/ .el-dialog__title {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }

  // 填写须知弹窗
  /deep/ .el-dialog__header {
    padding: 9px 0;
    text-align: center;
    background: linear-gradient(134deg, #66d4ff 0%, #3971ff 100%);
  }

  /deep/ .el-dialog__headerbtn {
    top: 9px;
    right: 9px;
    font-size: 24px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
  }

  /deep/ .el-dialog__body {
    text-align: justify;
  }

  /deep/ .el-dialog__footer {
    padding: 10px 20px 20px 0;
  }

  .tips {
    margin-top: -15px;
    margin-bottom: -15px;
    font-size: 13px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #4e555d;
    line-height: 25px;

    p {
      margin-top: 10px;
      text-align: justify;
    }
  }

  .dialog-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .btn {
      width: 95px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      cursor: pointer;
      color: #ffffff;
      border-radius: 296px;
      background: linear-gradient(134deg, #66d4ff 0%, #3971ff 100%);
      box-shadow: 0px 11px 18px -10px rgba(0, 49, 123, 0.4);
    }
  }
}
</style>
