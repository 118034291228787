/**
 * 工作经验列表
 */
export const experienceList = [
  { label: "不限经验", value: 0 },
  { label: "1年以内", value: 1 },
  { label: "1-3年", value: 2 },
  { label: "3-5年", value: 3 },
  { label: "5-10年", value: 4 },
  { label: "10年以上", value: 5 },
];

/**
 * 学历列表
 */
export const educationList = [
  { label: "不限学历", value: 0 },
  { label: "大专以下", value: 1 },
  { label: "大专", value: 2 },
  { label: "本科", value: 3 },
  { label: "硕士", value: 4 },
  { label: "博士及以上", value: 5 },
];

/**
 * 工作类型
 */
export const workTypeList = [
  { label: "不限", value: 0 },
  { label: "全职", value: 1 },
  { label: "兼职", value: 2 },
];

/**
 * 工作类型-发布职位使用
 */
export const workTypeListForPublish = [
  { label: "不限", value: 0 },
  { label: "全职", value: 1 },
];

/**
 * 性别列表
 */
export const sexList = [
  { label: "男", value: 1 },
  { label: "女", value: 2 },
];

/**
 * 职位状态列表
 */
export const postStatusList = [
  { label: "全部职位", value: "" },
  { label: "开放中", value: 1 },
  { label: "审核失败", value: 4 },
  { label: "待审核", value: 3 },
  { label: "已关闭", value: 0 },
  // { label: '已失效', value: 2 },
];

/**
 * 公司规模列表
 */
export const companyScaleList = [
  { label: "0-20", value: 1 },
  { label: "20-99", value: 2 },
  { label: "100-499", value: 3 },
  { label: "500+", value: 4 },
];

/**
 * 薪资列表
 */
export const salaryList = [
  { label: "1000", value: 1000 },
  { label: "2000", value: 2000 },
  { label: "3000", value: 3000 },
  { label: "4000", value: 4000 },
  { label: "5000", value: 5000 },
  { label: "6000", value: 6000 },
  { label: "7000", value: 7000 },
  { label: "8000", value: 8000 },
  { label: "9000", value: 9000 },
  { label: "10000", value: 10000 },
  { label: "11000", value: 11000 },
  { label: "12000", value: 12000 },
  { label: "13000", value: 13000 },
  { label: "14000", value: 14000 },
  { label: "15000", value: 15000 },
  { label: "16000", value: 16000 },
  { label: "17000", value: 17000 },
  { label: "18000", value: 18000 },
  { label: "19000", value: 19000 },
  { label: "20000", value: 20000 },
  { label: "21000", value: 21000 },
  { label: "22000", value: 22000 },
  { label: "23000", value: 23000 },
  { label: "24000", value: 24000 },
  { label: "25000", value: 25000 },
  { label: "26000", value: 26000 },
  { label: "27000", value: 27000 },
  { label: "28000", value: 28000 },
  { label: "29000", value: 29000 },
  { label: "30000", value: 30000 },
  { label: "31000", value: 31000 },
  { label: "32000", value: 32000 },
  { label: "33000", value: 33000 },
  { label: "34000", value: 34000 },
  { label: "35000", value: 35000 },
  { label: "36000", value: 36000 },
  { label: "37000", value: 37000 },
  { label: "38000", value: 38000 },
  { label: "39000", value: 39000 },
  { label: "40000", value: 40000 },
  { label: "41000", value: 41000 },
  { label: "42000", value: 42000 },
  { label: "43000", value: 43000 },
  { label: "44000", value: 44000 },
  { label: "45000", value: 45000 },
  { label: "46000", value: 46000 },
  { label: "47000", value: 47000 },
  { label: "48000", value: 48000 },
  { label: "49000", value: 49000 },
  { label: "50000", value: 50000 },
];

/**
 * 融资阶段列表
 */
export const financingStageList = [
  { label: "已上市", value: 0 },
  { label: "未融资", value: 1 },
  { label: "天使轮", value: 2 },
  { label: "A轮", value: 3 },
  { label: "B轮", value: 4 },
  { label: "C轮", value: 5 },
];

/**
 * 加班情况列表
 */
export const overtimeList = [
  { label: "不加班", value: 1 },
  { label: "偶尔加班", value: 2 },
  { label: "弹性加班", value: 3 },
];

/**
 * 休息情况列表
 */
export const restTimeList = [
  { label: "双休", value: 1 },
  { label: "排班轮休", value: 2 },
];

/**
 * 求职状态列表
 */
export const jobStatusList = [
  { label: "离职随时到岗", value: 1 },
  { label: "在职月内到岗", value: 2 },
  { label: "在职考虑机会", value: 3 },
  { label: "在职暂不考虑", value: 4 },
];
export const salaryTypeList = [
  { label: "手动输入-区间", value: 1 },
  { label: "手动输入-固定", value: 2 },
  { label: "面议", value: 3 },
];
/* 薪资单位 */
export const salaryUnitList = [
  // { label: "无", value: 0 },
  { label: "小时", value: 1 },
  { label: "天", value: 2 },
  { label: "单", value: 3 },
];
/**
 * 根据状态获取对应的文本
 */
export const getTextByStatus = (type, status) => {
  if (type == "experience") {
    // 工作经验
    return experienceList.find((item) => item.value === status)
      ? experienceList.find((item) => item.value === status)["label"]
      : "";
  } else if (type == "education") {
    // 学历
    return educationList.find((item) => item.value === status)
      ? educationList.find((item) => item.value === status)["label"]
      : "";
  } else if (type == "workType") {
    // 工作类型
    return workTypeList.find((item) => item.value === status)
      ? workTypeList.find((item) => item.value === status)["label"]
      : "";
  } else if (type == "sex") {
    // 职位状态
    return sexList.find((item) => item.value === status) ? sexList.find((item) => item.value === status)["label"] : "";
  } else if (type == "postStatus") {
    // 职位状态
    return postStatusList.find((item) => item.value === status)
      ? postStatusList.find((item) => item.value === status)["label"]
      : "";
  } else if (type == "scale") {
    // 公司规模
    return companyScaleList.find((item) => item.value === status)
      ? companyScaleList.find((item) => item.value === status)["label"]
      : "";
  } else if (type == "financing") {
    // 融资阶段
    return financingStageList.find((item) => item.value === status)
      ? financingStageList.find((item) => item.value === status)["label"]
      : "";
  } else if (type == "jobStatus") {
    // 求职状态
    return jobStatusList.find((item) => item.value === status)
      ? jobStatusList.find((item) => item.value === status)["label"]
      : "";
  }
};

export const getSalaryUnit = (value = 0) => {
  if (!value) return "";
  const item = salaryUnitList.find((item) => item.value === value);
  if (item) {
    return "/" + item.label;
  } else {
    return "";
  }
};

export const getSalaryNum = ({ salaryType, basicSalary, salaryUnit, salaryStart, salaryEnd }) => {
  if (!salaryType) return "面议";
  if (salaryType === 1) {
    let str = "";
    if (salaryStart === 0 && salaryEnd === 0) {
      return "面议";
    }
    if (salaryStart < 10000) {
      str += salaryStart + "元";
    } else {
      str += Math.floor(salaryStart / 1000) + "k";
    }
    str += "-";
    const unit = getSalaryUnit(salaryUnit);
    if (salaryEnd < 10000) {
      str += salaryEnd + "元" + unit;
    } else {
      str += Math.floor(salaryEnd / 1000) + "k" + unit;
    }
    return str;
  }
  if (salaryType === 2) {
    if (basicSalary === 0) {
      return "面议";
    }
    const unit = getSalaryUnit(salaryUnit);
    if (basicSalary < 10000) {
      return basicSalary + "元" + unit;
    } else {
      return Math.floor(basicSalary / 1000) + "k" + unit;
    }
  }
  if (salaryType === 3) {
    return "面议";
  }
};
