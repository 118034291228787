<template>
  <div class="company-container">
    <!-- 资料菜单 -->
    <div class="company-left">
      <div class="title-box">
        <p class="title">编辑公司信息</p>
        <p class="agrement">请认真阅读并遵守<span @click="showFillNoticeDialog = true">《公司信息填写须知》</span></p>
      </div>
      <div class="data-progress">
        <p class="text">
          <span>公司完善状态：</span>
          <span class="blue">{{ doneProgress }}%</span>
        </p>
        <el-progress :show-text="false" :percentage="doneProgress"></el-progress>
        <p class="text">
          <span>公司状态：</span>
          <!-- 待审核 -->
          <template v-if="getCompanyInfo.auditStatus == 0">
            <span class="blue">审核中</span>
            <span class="gray">审核通过后可发布职位</span>
          </template>
          <!-- 审核通过 -->
          <template v-else-if="getCompanyInfo.auditStatus == 1">
            <span class="green">正常</span>
          </template>
          <!-- 审核拒绝 -->
          <template v-else-if="getCompanyInfo.auditStatus == 2">
            <span class="red">审核失败</span>
            <span class="gray underline" @click="showAuditStatusDialog = true">查看原因</span>
          </template>
          <!-- 未完善企业信息 -->
          <template v-else-if="getCompanyInfo.auditStatus == 99">
            <span class="red">未完善</span>
            <span class="gray">完善企业信息后可发布职位</span>
          </template>
        </p>
      </div>
      <div class="data-menu">
        <div :to="item.path" tag="div" :class="['menu-item', activedMenuIndex == index ? 'actived' : '']" v-for="(item, index) in menuList" :key="index" @click="handleChangeMenu(item, index)">
          <p>{{ item.title }}</p>
          <p class="doneText">
            <template v-if="item.fieldList.length">
              <span v-if="item.doneNumber == 0">未完善</span>
              <span v-else-if="item.doneNumber == item.totalNumber">已完善</span>
              <span v-else-if="item.doneNumber !== item.totalNumber">{{ item.doneNumber }}/{{ item.totalNumber }}</span>
            </template>
            <img src="@/assets/images/company/arrow-icon.png" class="icon" alt="" />
          </p>
        </div>
      </div>
      <div class="preview-btn" @click="showPreviewCompanyDialog = true">预览</div>
    </div>

    <!-- 公司信息填写须知弹窗 -->
    <MessageBox v-if="showFillNoticeDialog" title="公司信息填写须知" width="670px" @confirm="showFillNoticeDialog = false">
      <template>
        <p>您在本页面编辑、上传的公司信息将作为招聘场景下的公司主页展示，供求职者浏览。为保障求职者合法权益并确保您的招聘效果，您承诺并确认：</p>
        <p>1、您的填写行为已获得所在公司的授权，相关内容经过公司确认。</p>
        <p>2、填写、上传真实、合法、有效的公司信息，据实填写并及时更新公司介绍、规模、福利等，以免对求职者造成误导。</p>
        <p>3、填写、上传的图文、视频等资料不存在违法违规或涉嫌侵犯第三方合法权益的情形，如由此产生相关法律风险或造成损失，由您及所在公司承担法律责任。</p>
        <p>4、您授权聘播播以提供招聘服务为目的在其他场景下免费使用您在本页面填写的公司信息，以便为您提供更佳的服务体验。</p>
      </template>
    </MessageBox>

    <!-- 预览公司 -->
    <PreviewCompany v-if="showPreviewCompanyDialog" @cancel="showPreviewCompanyDialog = false" />

    <!-- 公司审核状态弹窗 -->
    <CompanyAuditStatus v-if="showAuditStatusDialog" @cancel="showAuditStatusDialog = false" />

    <!-- 资料内容 -->
    <div class="company-right">
      <router-view />
    </div>
  </div>
</template>

<script>
let menuList = [
  // 菜单列表
  {
    title: '公司基本信息',
    path: '/company/info',
    fieldList: ['logoUrl', 'attachmentUrl', 'imageList', 'name', 'scaleType', 'businessLicenseUrl', 'intro'],
  },
  // {
  //   title: '公司介绍',
  //   path: '/company/introduce',
  //   fieldList: ['intro'],
  // },
  {
    title: '主营业务',
    path: '/company/business',
    fieldList: ['businessList'],
  },
  // {
  //   title: '公司相册',
  //   path: '/company/photo',
  //   fieldList: ['photoPictureList', 'photoVideoList'],
  // },
  {
    title: '公司福利',
    path: '/company/treatment',
    fieldList: ['overTimeType', 'restTimeType', 'workStartTime', 'welfareList'],
  },
  {
    title: '账号管理',
    path: '/company/account',
    fieldList: [],
  },
]
menuList.forEach((item) => {
  item.doneNumber = 0
  item.totalNumber = item.fieldList.length
})

import { mapGetters } from 'vuex'
import CompanyAuditStatus from '@/components/CompanyAuditStatus'
import MessageBox from '@/components/MessageBox.vue'
import PreviewCompany from '@/components/PreviewCompany.vue'
export default {
  name: 'CompanyView',
  components: { CompanyAuditStatus, MessageBox, PreviewCompany },
  data() {
    return {
      showAuditStatusDialog: false, // 是否显示审核状态弹窗
      showFillNoticeDialog: false, // 是否显示公司信息填写须知弹窗
      showPreviewCompanyDialog: false, // 是否显示预览公司弹窗
      doneProgress: 0, // 完成进度
      activedMenuIndex: 0, // 激活的索引
      menuList: menuList, // 菜单列表
    }
  },
  computed: {
    ...mapGetters(['getCompanyInfo']),
  },
  watch: {
    $route: {
      handler(to) {
        this.activedMenuIndex = this.menuList.findIndex((item) => to.path.startsWith(item.path))
      },
      immediate: true,
    },
    // 监听资料完善情况根据字段计算完成进度步骤
    '$store.getters.getCompanyInfo': {
      handler(companyInfo) {
        // 把项目列表拆分成项目图片和项目视频列表
        companyInfo.photoPictureList = []
        companyInfo.photoVideoList = []
        companyInfo.photoList.forEach((item) => {
          item.attachmentType === 1 ? companyInfo.photoPictureList.push(item) : companyInfo.photoVideoList.push(item)
        })

        // 更新完成状态
        this.menuList.map((item) => (item.doneNumber = 0))
        this.doneProgress = 0
        for (const key in companyInfo) {
          for (let index = 0; index < this.menuList.length; index++) {
            if (this.menuList[index].fieldList.findIndex((item) => item == key) !== -1) {
              // 更新完成数量
              if (String(companyInfo[key]).length) {
                this.menuList[index].doneNumber++
              }

              // 更新进度
              if (this.menuList[index].doneNumber === this.menuList[index].totalNumber) {
                this.doneProgress + Math.ceil(10000 / 3) / 100 > 100 ? (this.doneProgress = 100) : (this.doneProgress += Math.ceil(10000 / 3) / 100)
              }
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 切换菜单
     */
    handleChangeMenu(item, index) {
      if (this.activedMenuIndex == index) return
      this.activedMenuIndex = index
      this.$router.push(item.path)
    },
  },
}
</script>

<style lang="less" scoped>
@content-height: 90vh;

.company-container {
  margin-top: 28px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  min-height: calc(@content-height - 28px);
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 15px #dee1ea;

  .company-left {
    width: 400px;
    background-color: #f8f9fc;
    padding-bottom: 20px;

    .title-box {
      display: flex;
      flex-direction: column;
      padding: 0 30px;

      .title {
        margin-top: 48px;
        font-size: 22px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 400;
        color: #171d26;
      }

      .agrement {
        margin-top: 6px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #777777;

        span {
          font-family: PingFang SC-Bold, PingFang SC;
          color: #3971ff;
          cursor: pointer;
        }
      }
    }

    .data-progress {
      margin-top: 49px;
      display: flex;
      flex-direction: column;
      padding: 0 30px;

      .text {
        font-size: 15px;
        font-weight: 400;
        color: #323741;
        font-family: PingFang SC-Medium, PingFang SC;
      }

      .red {
        color: #ff4949;
      }

      .blue {
        color: #3971ff;
      }

      .green {
        color: #3dea0d;
      }

      .gray {
        color: #7e8189;
        margin-left: 10px;
      }

      .underline {
        cursor: pointer;
        text-decoration: underline;
      }

      /deep/ .el-progress {
        margin-top: 15px;
        margin-bottom: 15px;

        .el-progress-bar__inner {
          background: linear-gradient(134deg, #3971ff 0%, #66d4ff 100%);
        }

        .el-progress-bar__outer {
          height: 8px !important;
          width: 100%;
          border-radius: 5px !important;
          background-color: #e2e5eb;
        }
      }
    }

    .data-menu {
      margin-top: 60px;
      display: flex;
      flex-direction: column;

      .menu-item {
        width: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 54px;
        font-size: 15px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #323741;
        background-position: center;
        background-size: 100% 100%;
        cursor: pointer;

        &:hover {
          background-color: #ffffff;
        }

        .icon {
          margin-left: 19px;
          width: 10px;
          height: 10px;
        }

        .doneText {
          color: #7e8189;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
        }
      }

      .actived {
        background-image: url('@/assets/images/company/active-menu-bg.png');
        color: #ffffff;

        .doneText {
          color: #ffffff;
        }
      }
    }

    .preview-btn {
      margin: 0 auto;
      margin-top: 57px;
      width: 230px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      border-radius: 30px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #323741;
      background-color: #ffffff;
      cursor: pointer;
      border: 1px solid #e0e4eb;
      transition: all 0.3s;

      &:hover {
        background-color: #e3ebff;
      }
    }
  }

  .company-right {
    flex: 1;
    background-color: #ffffff;
    padding: 48px 49px;
    padding-bottom: 0;
  }
}
</style>
