import request from '@/utils/request';

/**
 * 获取职位列表
 */
export const getPostList = data => {
  return request({
    url: '/web/enterprise/company/position/list',
    method: 'post',
    data
  });
};

/**
 * 获取职位下的简历列表
 */
export const getPostResumeList = data => {
  return request({
    url: '/web/enterprise/position/vita/list',
    method: 'post',
    data
  });
};

/**
 * 获取职位类型列表
 */
export const getPositionTypeList = data => {
  return request({
    url: '/web/system/positionType/list',
    method: 'post',
    data
  });
};


/**
 * 发布职位
 */
export const addPost = data => {
  return request({
    url: '/web/enterprise/company/position/add',
    method: 'post',
    data
  });
};

/**
 * 编辑职位
 */
export const updatePost = data => {
  return request({
    url: '/web/enterprise/company/position/update',
    method: 'post',
    data
  });
};

/**
 * 获取职位信息
 */
export const getPostById = data => {
  return request({
    url: '/web/enterprise/company/position/preview',
    method: 'post',
    data
  });
};

/**
 * 关闭职位
 */
export const closePost = data => {
  return request({
    url: '/web/enterprise/company/position/control',
    method: 'post',
    data: {
      ...data,
      status: 0
    }
  });
};

/**
 * 开启职位
 */
export const openPost = data => {
  return request({
    url: '/web/enterprise/company/position/control',
    method: 'post',
    data: {
      ...data,
      status: 1
    }
  });
};

/**
 * 删除职位
 */
export const deletePost = data => {
  return request({
    url: '/web/enterprise/company/position/delete',
    method: 'post',
    data
  });
};

/**
 * 打招呼
 */
export const sayHello = data => {
  return request({
    url: '/web/enterprise/position/connect',
    method: 'post',
    data
  });
};

/**
 * 发布职位字段模板
 */
export const getPublishFieldTemplate = data => {
  return request({
    url: '/web/enterprise/position/template/detail',
    method: 'post',
    data
  });
};


