var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isOverlayShow)?_c('div',{ref:"overlayDomRef",staticClass:"overlay",style:({
    position: _vm.props.isFullScreen ? 'fixed' : 'absolute',
    zIndex: _vm.props.zIndex,
  }),on:{"click":_vm.onOverlayClick}},[(_vm.props.useMask)?_c('div',{class:{
      'overlay-mask': true,
      'fade-in': _vm.props.visible,
    },style:({
      backgroundColor: _vm.props.maskColor,
    })}):_vm._e(),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_vm._t("default")],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }