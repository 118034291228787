<template>
  <div class="preview-company-container">
    <!-- 预览公司弹窗 -->
    <el-dialog title="" :visible="true" top="60px" custom-class="preview-company-dialog" :before-close="handleCancle">
      <div class="company-info">
        <el-image class="cover" fit="cover" :src="getCompanyInfo.logoUrl"></el-image>
        <div class="info">
          <p class="title">{{ getCompanyInfo.name }}</p>
          <div class="text">
            <p>{{ getTextByStatus('scale', getCompanyInfo.scaleType) }}人</p>
          </div>
          <div class="tag-list">
            <p class="tag-item" v-for="(item, index) in getCompanyInfo.welfareList" :key="index">{{ item.name }}</p>
          </div>
        </div>
        <div class="number-list">
          <div class="number-item">
            <p class="number">{{ getCompanyInfo.likedCount || 0 }}</p>
            <p class="text">获赞数</p>
          </div>
          <div class="divide"></div>
          <div class="number-item">
            <p class="number">{{ getCompanyInfo.followedCount || 0 }}</p>
            <p class="text">关注人数</p>
          </div>
        </div>
      </div>
      <div class="company-content">
        <p class="label">公司介绍</p>
        <div class="intro">
          <template v-if="getCompanyInfo.intro.length">
            <template v-if="getCompanyInfo.intro.length > 120">
              <p :class="['text', showAllIntroduce ? 'max-text' : '']">{{ showAllIntroduce ? getCompanyInfo.intro : getCompanyInfo.intro.substring(0, 120) }}</p>
              <p class="more-btn" @click="showAllIntroduce = !showAllIntroduce">{{ showAllIntroduce ? '收起' : '展开查看' }}</p>
            </template>
            <p v-else class="text">{{ getCompanyInfo.intro }}</p>
          </template>
          <p v-else class="none-text">暂无</p>
        </div>
        <p class="label">公司相册</p>
        <div class="swiper-box" v-if="getCompanyInfo.photoList.length">
          <template v-if="getCompanyInfo.photoList.length > 5">
            <div class="swiper-button-prev swiper-button-prev1" slot="button-prev">
              <img class="icon left" src="@/assets/images/company/arrow-icon.png" alt="" />
            </div>
            <div class="swiper-button-next swiper-button-next1" slot="button-next">
              <img class="icon" src="@/assets/images/company/arrow-icon.png" alt="" />
            </div>
          </template>
          <swiper :options="swiperOption1" class="picture-list">
            <swiper-slide v-for="(item, index) in getCompanyInfo.photoList" :key="index" class="picture-item">
              <!-- 图片 -->
              <el-image v-if="item.attachmentType == 1" class="img" fit="cover" :src="item.attachmentUrl" :preview-src-list="[item.attachmentUrl]"></el-image>
              <!-- 视频 -->
              <div v-else-if="item.attachmentType == 2" class="video-box" @click="handlePreviewVideo(item.coverUrl, item.attachmentUrl)">
                <el-image class="cover" fit="cover" :src="item.coverUrl"></el-image>
                <img class="icon" src="@/assets/images/company/video3-icon.png" alt="" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <p v-else class="none-text">暂无</p>

        <template v-if="getCompanyInfo.attachmentType == 2">
          <div class="column" v-if="getCompanyInfo.coverUrl">
            <p class="label">宣传视频</p>
            <div class="video-wrap" @click="handlePreviewVideo(getCompanyInfo.coverUrl, getCompanyInfo.attachmentUrl)">
              <el-image class="video" fit="cover" :src="getCompanyInfo.coverUrl"></el-image>
              <div class="icon-box">
                <img class="icon" src="@/assets/images/company/video3-icon.png" alt="" />
              </div>
            </div>
          </div>
          <p v-else class="none-text">暂无</p>
        </template>

        <template v-else-if="getCompanyInfo.attachmentType == 1">
          <p class="label">宣传内容</p>
          <div class="swiper-box" v-if="getCompanyInfo.imageList.length">
            <template v-if="getCompanyInfo.imageList.length > 5">
              <div class="swiper-button-prev swiper-button-prev2" slot="button-prev">
                <img class="icon left" src="@/assets/images/company/arrow-icon.png" alt="" />
              </div>
              <div class="swiper-button-next swiper-button-next2" slot="button-next">
                <img class="icon" src="@/assets/images/company/arrow-icon.png" alt="" />
              </div>
            </template>
            <swiper :options="swiperOption2" class="picture-list">
              <swiper-slide v-for="(item, index) in getCompanyInfo.imageList" :key="index" class="picture-item">
                <!-- 图片 -->
                <el-image class="img" fit="cover" :src="item" :preview-src-list="getCompanyInfo.imageList"></el-image>
              </swiper-slide>
            </swiper>
          </div>
          <p v-else class="none-text">暂无</p>
        </template>

        <p class="label">主营业务</p>
        <div class="business-list" v-if="getCompanyInfo.businessList.length">
          <div class="business-item" v-for="(item, index) in getCompanyInfo.businessList" :key="index">
            <p class="name">{{ item.name }}</p>
          </div>
        </div>
        <p v-else class="none-text">暂无</p>
        <p class="label">在招职位</p>
        <div class="post-list" v-if="positionList.filter((item) => item.status == 1).length">
          <div class="post-item" v-for="(item, index) in positionList.filter((item) => item.status == 1)" :key="index">
            <div class="name-box">
              <p class="name">{{ item.name }}</p>
              <p class="price">{{ item | getSalaryNum}}</p>
            </div>
            <p class="text">
              <span>{{ item.cityName }}</span>
              <span> / </span>
              <span>{{ item.liveTypeName }}</span>
              <span> / </span>
              <span>{{ getTextByStatus('workType', item.jobType) }}</span>
            </p>
            <p class="text">{{ item.location }}</p>
          </div>
        </div>
        <p v-else class="none-text">暂无</p>
      </div>
    </el-dialog>

    <!-- 预览视频弹窗 -->
    <PreviewVideo v-if="showPreviewVideoDialog" :videoInfo="previewVideoInfo" @cancel="showPreviewVideoDialog = false" />
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import PreviewVideo from '@/components/PreviewVideo.vue'
import { mapGetters } from 'vuex'
import { getTextByStatus } from '@/utils/statusType'
import { getPostList } from '@/api/position'
export default {
  name: 'PriviewCompany',
  components: { PreviewVideo },
  data() {
    return {
      showPreviewVideoDialog: false, // 是否显示预览视频弹窗
      showAllIntroduce: false, // 是否展示所有公司介绍
      swiperOption1: {
        loop: false,
        loopAdditionalSlides: 2,
        loopedSlides: 1,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-button-next1',
          prevEl: '.swiper-button-prev1',
        },
        spaceBetween: 12,
      },
      swiperOption2: {
        loop: false,
        loopAdditionalSlides: 2,
        loopedSlides: 1,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-button-next2',
          prevEl: '.swiper-button-prev2',
        },
        spaceBetween: 12,
      },
      positionList: [], // 公司职位列表
      previewVideoInfo: {}, // 预览视频信息
      AMap: null, // 地图对象
      map: null, // 地图实例对象
      marker: null, // 地图标记点
    }
  },
  computed: {
    ...mapGetters(['getCompanyInfo']),
  },
  created() {
    this.fetchPositionList()
  },
  methods: {
    getTextByStatus,

    /**
     * 获取职位列表
     */
    async fetchPositionList() {
      let { data } = await getPostList({
        status: '1',
        keyWords: '',
        pageNum: 1,
        pageSize: 100000000,
      })
      this.positionList = data.list
    },

    /**
     * 创建地图
     */
    initMap() {
      AMapLoader.load({
        key: this.$config.mapWebKey,
        version: '2.0',
        plugins: ['AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete'],
      }).then((AMap) => {
        this.AMap = AMap
        this.map = new AMap.Map('map-box', {
          viewMode: '3D',
          zoom: 15,
          // center: [116.406315, 39.908775], // 初始化地图中心点位置
          mapStyle: 'amap://styles/normal', // 设置地图的显示样式
        })
        this.marker = new AMap.Marker({
          // position: new AMap.LngLat(116.406315, 39.908775),
          icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
          title: '标记带你',
        })
        // 添加新的标记点
        this.map.add(this.marker)
      })
    },

    /**
     * 预览视频
     */
    handlePreviewVideo(coverUrl, videoUrl) {
      this.previewVideoInfo = {
        coverUrl,
        videoUrl,
      }
      this.showPreviewVideoDialog = true
    },

    /**
     * 关闭取消
     */
    handleCancle() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="less" scoped>
.preview-company-container {
  /deep/ .el-dialog__wrapper {
    overflow-x: hidden;
    backdrop-filter: blur(30px);
  }

  // 预览公司
  /deep/ .preview-company-dialog {
    width: 1105px;
    border-radius: 20px;
    overflow: hidden;
    background: #000000;
    // margin-left: calc(50% - 280px);
    // transform: translateX(-21.5%);

    .el-dialog__body {
      padding: 0 0;
      margin-top: -30px;
    }

    .el-dialog__headerbtn {
      top: 15px;
      right: 15px;
      font-size: 28px;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #9b9b9b !important;
    }

    .company-info {
      padding: 22px 0 16px 0;
      background-color: #262a33;
      display: flex;
      flex-direction: row;

      .cover {
        width: 80px;
        height: 80px;
        margin-right: 27px;
        margin-left: 66px;
        border-radius: 6px;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .title {
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }

        .text {
          display: flex;
          flex-direction: row;
          font-size: 14px;

          .divide {
            margin: 0 3px;
          }
        }

        .tag-list {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;

          .tag-item {
            height: 19px;
            line-height: 19px;
            background: rgba(244, 244, 244, 0.05);
            padding: 0 10px;
            font-size: 10px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #717171;
            margin-right: 13px;
            margin-top: 8px;
          }
        }
      }

      .number-list {
        margin-right: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .number-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          .number {
            font-size: 26px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }

          .text {
            margin-top: 5px;
            height: 11px;
            font-size: 11px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }

        .divide {
          margin: 0 30px;
          width: 1px;
          height: 25px;
          background: #555555;
        }
      }
    }

    .company-content {
      padding: 0 36px;
      padding-bottom: 40px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 240px);
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #cfcfcf;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        background: #e0e0e0;
      }

      .label {
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 700;
        color: #333333;
        margin-top: 30px;
        margin-left: 30px;
      }

      .none-text {
        margin-left: 30px;
        margin-top: 7px;
        font-size: 15px;
        line-height: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #888888;
      }

      .swiper-box {
        position: relative;
        width: 100%;
        margin-top: 10px;

        .swiper-button-prev,
        .swiper-button-next {
          background-image: none;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            width: 10px;
            height: 10px;
          }

          .left {
            transform: rotate(180deg);
          }
        }

        .swiper-button-prev {
          left: 0;
        }

        .swiper-button-next {
          right: 0;
        }

        .picture-list {
          width: 94%;

          .picture-item {
            width: 19%;
            height: 124px;

            .img {
              border-radius: 3px;
              overflow: hidden;
              width: 100%;
              height: 100%;
            }

            .video-box {
              width: 100%;
              height: 100%;
              border-radius: 2px;
              position: relative;
              cursor: pointer;
              border-radius: 3px;
              overflow: hidden;

              &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color: #262a339d;
                position: absolute;
                left: 0;
                bottom: 0;
              }

              .cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
              }

              .icon {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 49px;
                height: 49px;
                z-index: 1;
              }
            }
          }
        }
      }

      .business-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 30px;

        .business-item {
          background: rgb(244, 244, 244);
          padding: 5px 10px;
          font-size: 15px;
          font-weight: 400;
          margin-right: 13px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 350;
          color: #666666;
          margin-top: 10px;
          border-radius: 3px;
        }
      }

      .intro {
        display: flex;
        flex-direction: column;

        .text {
          margin-top: 7px;
          font-size: 15px;
          line-height: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 350;
          color: #666666;
          margin-left: 30px;
        }

        .max-text {
          height: auto;
        }

        .more-btn {
          font-size: 15px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #3870ff;
          text-align: right;
          display: block;
          float: right;
          cursor: pointer;
        }
      }

      .post-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 30px;

        .post-item {
          width: 49%;
          height: 85px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          padding: 10px 20px 10px 14px;
          margin-top: 11px;
          border: 1px solid #f3f3f3;
          background-color: #f8f9fc;

          .name-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .name {
              flex: 1;
              font-size: 14px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: 400;
              color: #222222;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .price {
              margin-left: 10px;
              height: 14px;
              font-size: 14px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: 400;
              color: #ee502f;
            }
          }

          .text {
            margin-top: 5px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .column {
          flex: 1;
          display: flex;
          flex-direction: column;

          .text {
            margin-top: 7px;
            font-size: 15px;
            line-height: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 350;
            color: #666666;
            margin-left: 30px;
          }
        }
      }

      .video-wrap {
        position: relative;
        width: 183px;
        height: 124px;
        cursor: pointer;
        margin-left: 30px;
        margin-top: 10px;
        overflow: hidden;
        border-radius: 5px;

        .video {
          width: 100%;
          height: 100%;
        }

        .icon-box {
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: #262a339d;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            position: absolute;
            width: 50px;
            height: 50px;
            z-index: 1;
          }
        }
      }
    }
  }
}
</style>
